import { FC, useEffect } from 'react';
import { AddButton, Layout } from '@fleet/shared';
import { CardHeader } from '@fleet/shared/mui';
import { Divider } from '@mui/material';
import { BusinessCustomerTable } from './BusinessCustomerTable';
import { BusinessCustomerSearch } from './BusinessCustomerSearch';
import { useTranslation } from 'react-i18next';
import { TransHeader } from 'i18n/trans/header';
import { TransButton } from 'i18n/trans/button';
import { useHistory } from 'react-router-dom';

export const BusinessCustomer: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    document.title = t('title.businessCustomer', 'Business customer');
  }, [t]);

  return (
    <Layout
      header={
        <CardHeader title={<TransHeader i18nKey="businessCustomer" />}>
          <AddButton
            onClick={() => history.push('/customers/business_customers/create')}
          >
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
    >
      <>
        <BusinessCustomerSearch />
        <Divider />
        <BusinessCustomerTable />
      </>
    </Layout>
  );
};
