import { FC } from 'react';
import { TransHeader } from 'i18n/trans/header';
import { AddButton, CardHeader, Layout } from '@fleet/shared';
import { useHistory } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { CustomerProfileTable } from 'routes/customerProfile/CustomerProfileTable';

export const CustomerProfileList: FC = () => {
  const history = useHistory();

  return (
    <Layout
      header={
        <CardHeader title={<TransHeader i18nKey="customerProfile" />}>
          <AddButton
            onClick={() => history.push('/customer_profiles/create')}
            disabled
          >
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
    >
      <CustomerProfileTable />
    </Layout>
  );
};
