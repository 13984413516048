import React, { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {
  Checkbox,
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { Row, useFilters, useRowSelect } from 'react-table';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import {
  businessCustomerContactPersonFilterSelector,
  businessCustomerContactPersonsSelector,
  currentBusinessCustomerSelector,
} from 'features/businessCustomer/businessCustomerSelectors';
import { businessCustomerTabsLoadingSelector } from 'features/loading/loadingSelectors';
import {
  deleteBusinessCustomerContactPerson,
  getBusinessCustomerContactPersons,
} from 'features/businessCustomer/businessCustomerActions';
import { BusinessCustomerContactPerson } from 'dto/businessCustomer';
import { TransTableHead } from 'i18n/trans/table';
import { TransAlert } from 'i18n/trans/alert';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';

export const ContactPersons: FC = () => {
  const contactPersons = useSelector(businessCustomerContactPersonsSelector);
  const data = useMemo(() => contactPersons?.items ?? [], [contactPersons]);
  const loading = useSelector(businessCustomerTabsLoadingSelector);
  const filter = useSelector(businessCustomerContactPersonFilterSelector);
  const businessCustomer = useSelector(currentBusinessCustomerSelector);
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getBusinessCustomerContactPersons({ OnlyActive: true }));
  }, [dispatch]);

  const link = useCallback(
    (row: Row<BusinessCustomerContactPerson>) =>
      `/customers/business_customers/edit/${businessCustomer?.id}/contacts/edit/${row.original.id}`,
    [businessCustomer?.id]
  );

  const columns = useMemo<TableColumns<BusinessCustomerContactPerson>>(
    () => [
      {
        accessor: 'firstName',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row }) => {
          return (
            <NavLink to={link(row)}>
              {row.original.firstName + ' ' + row.original.lastName}
            </NavLink>
          );
        },
      },
      {
        id: 'position',
        accessor: 'position',
        Header: <TransTableHead i18nKey="position" />,
      },
      {
        id: 'unit',
        accessor: ({ unit }) => unit?.name,
        Header: <TransTableHead i18nKey="unit" />,
      },
      {
        id: 'isActive',
        accessor: ({ isActive }) => (
          <Icon
            name={isActive ? 'check' : 'close'}
            color={isActive ? 'success' : 'error'}
          />
        ),
        Header: <TransTableHead i18nKey="active" />,
        editableProps: {
          required: false,
        },
        type: 'checkbox',
      },
    ],
    [link]
  );

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      form,
      data,
      columns,
      initialState: {
        pageSize: 10,
      },
      pageCount: -1,
      manualPagination: true,
    },
    useFilters,
    useIndeterminateRowSelectCheckbox,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteBusinessCustomerContactPerson(
        table.selectedFlatRows.map((row) => row.original.id)
      )
    )
      .unwrap()
      .then(() => {
        dispatch(getBusinessCustomerContactPersons());
        alert.success(
          <TransAlert i18nKey="businessCustomerContactProfileDeleted" />
        );
      });
  }, [alert, dispatch, table.selectedFlatRows]);

  const { removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  const handleIsActiveFilterToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      await dispatch(
        getBusinessCustomerContactPersons(
          e.target.checked
            ? {
                OnlyActive: e.target.checked,
              }
            : {}
        )
      );
    },
    [dispatch]
  );

  return (
    <>
      <Loadable loading={loading}>
        <FormProvider {...form}>
          <Box sx={{ mb: 6 }}>
            <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
              <div>
                <Checkbox
                  checked={filter?.OnlyActive}
                  onChange={handleIsActiveFilterToggle}
                  label={
                    <Typography
                      variant="body2"
                      color="text.primary"
                      component="span"
                    >
                      <TransField i18nKey="showActiveContacts" />
                    </Typography>
                  }
                  size="small"
                />
              </div>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ ml: 'auto' }}
              >
                <Button
                  variant="text"
                  onClick={removeSelectedRows}
                  startIcon={<Icon name="delete" />}
                  disabled={!table.selectedFlatRows.length}
                  color="error"
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
                <Button
                  variant="text"
                  onClick={() =>
                    history.replace(
                      `/customers/business_customers/edit/${businessCustomer?.id}/contacts/create`
                    )
                  }
                  startIcon={<Icon name="plus" />}
                >
                  <TransButton i18nKey="addNew" />
                </Button>
              </Stack>
            </Stack>
            <Table
              getTableProps={{
                sx: {
                  tableLayout: 'fixed',
                },
              }}
              table={table}
            />
          </Box>
        </FormProvider>
      </Loadable>
    </>
  );
};
