import React, { useCallback, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Row, useFilters, usePagination, useRowSelect } from 'react-table';
import {
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { Icon } from '@fleet/shared/mui';
import { Box, Button, Divider, Stack } from '@mui/material';
import { formatDate } from '@fleet/shared/utils/date';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { EmployeeAccountsSearchForm } from './EmployeeAccountsSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentBusinessCustomerContractSelector,
  employeeAccountsFilterSelector,
  employeeAccountsSelector,
} from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { businessCustomerContractTabsLoadingSelector } from 'features/loading/loadingSelectors';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';
import {
  deactivateEmployeeAccounts,
  getEmployeeAccounts,
  setEmployeeAccounts,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { EmployeeAccount } from 'dto/businessCustomerContracts';
import { TransTableHead } from 'i18n/trans/table';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';

export const EmployeeAccountsTable = () => {
  const employeeAccounts = useSelector(employeeAccountsSelector);
  const contract = useSelector(currentBusinessCustomerContractSelector);
  const loading = useSelector(businessCustomerContractTabsLoadingSelector);
  const filter = useSelector(employeeAccountsFilterSelector);
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const getPage = useCallback(
    (pageSize: number) => {
      if (employeeAccounts) {
        const { limit = pageSize, offset } = employeeAccounts;
        return offset / limit;
      }
      return 0;
    },
    [employeeAccounts]
  );
  const data = useMemo(() => employeeAccounts?.items ?? [], [employeeAccounts]);

  useComponentWillUnmount(() => {
    dispatch(setEmployeeAccounts(undefined));
  });

  const link = useCallback(
    (row: Row<EmployeeAccount>) =>
      `/customers/business_customer_contracts/edit/${contract?.businessCustomer.id}/${contract?.id}/employee_accounts/edit/${row.original.id}`,
    [contract?.businessCustomer.id, contract?.id]
  );

  const columns = useMemo<TableColumns<EmployeeAccount>>(
    () => [
      {
        id: 'customerProfile.firstName',
        accessor: ({ customerProfile }) => customerProfile?.firstName,
        Header: <TransTableHead i18nKey="employeeAccountName" />,
        Cell: ({ row }: { row: Row<EmployeeAccount> }) => {
          return (
            <NavLink
              to={link(row)}
            >{`${row.original?.customerProfile?.firstName} ${row.original?.customerProfile?.lastName}`}</NavLink>
          );
        },
      },
      {
        id: 'customerProfileCard.number',
        accessor: ({ customerProfileCard }) => customerProfileCard?.number,
        Header: <TransTableHead i18nKey="cardNumber" />,
      },
      {
        id: 'customerProfile.email',
        accessor: ({ customerProfile }) => customerProfile?.email,
        Header: <TransTableHead i18nKey="email" />,
      },
      {
        id: 'credit.limit',
        accessor: ({ credit }) => credit?.limit,
        Header: (
          <TransTableHead
            i18nKey={
              contract?.credit.type.id === 'CREDIT_TYPE.MONTHLY_LIMIT'
                ? 'monthlyCreditLimit'
                : 'permanentCreditLimit'
            }
          />
        ),
      },
      {
        id: 'credit.dailyLimit',
        accessor: ({ credit }) => credit?.dailyLimit,
        Header: <TransTableHead i18nKey="dailyLimit" />,
      },
      {
        id: 'status.name',
        accessor: ({ status }) => status?.name,
        Header: <TransTableHead i18nKey="status" />,
      },
      {
        accessor: 'validity',
        Header: <TransTableHead i18nKey="valid" />,
        Cell: ({ value }) =>
          `${formatDate(value?.from)} - ${formatDate(value?.to)}`,
      },
    ],
    [contract?.credit.type.id, link]
  );

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getEmployeeAccounts({
          ...filter,
          ...paginationParams,
        })
      ),
    [dispatch, filter]
  );

  const table = useFormTable(
    {
      form,
      data,
      columns,
      initialState: {
        pageSize: 10,
      },
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: employeeAccounts?.totalCount,
    },
    useFilters,
    usePagination,
    useIndeterminateRowSelectCheckbox,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deactivateEmployeeAccounts(
        table.selectedFlatRows.map((row) => row.original.id)
      )
    )
      .unwrap()
      .then(() => {
        dispatch(getEmployeeAccounts());
        alert.success(<TransAlert i18nKey="employeeAccountRemoved" />);
      });
  }, [dispatch, table.selectedFlatRows, alert]);

  const { removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <>
      <EmployeeAccountsSearchForm />
      <Divider sx={{ mb: 3 }} />
      <Loadable loading={loading}>
        <FormProvider {...form}>
          <Box sx={{ mb: 6 }}>
            <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ ml: 'auto' }}
              >
                <Button
                  variant="text"
                  onClick={() =>
                    history.replace(
                      `/customers/business_customer_contracts/edit/${contract?.businessCustomer.id}/${contract?.id}/employee_accounts/create`
                    )
                  }
                  startIcon={<Icon name="plus" />}
                >
                  <TransButton i18nKey="addNew" />
                </Button>
                <Button
                  variant="text"
                  onClick={removeSelectedRows}
                  startIcon={<Icon name="deactivate" />}
                  disabled={!table.selectedFlatRows.length}
                >
                  <TransButton i18nKey="deactivate" />
                </Button>
              </Stack>
            </Stack>
            <Table
              getTableProps={{
                sx: {
                  tableLayout: 'fixed',
                },
              }}
              table={table}
            />
          </Box>
        </FormProvider>
      </Loadable>
    </>
  );
};
