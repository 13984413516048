import { FC, useCallback, useMemo } from 'react';
import { LoyaltyTier, LoyaltyTierLocalization } from 'dto/loyaltyProgram';
import {
  FormProvider,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useRowSelect } from 'react-table';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { languageOptions } from 'consts';
import { useDispatch } from 'store/utils';
import {
  deleteLoyaltyProgramTierLocalizations,
  getLoyaltyTierLocalizations,
  updateOrCreateLoyaltyProgramTierLocalization,
} from 'features/loyaltyProgram/loyaltyProgramActions';

interface TierLocalizationsProps {
  tier: LoyaltyTier;
}

export const TierLocalizations: FC<TierLocalizationsProps> = ({ tier }) => {
  const { localizations, id } = tier;
  const dispatch = useDispatch();

  const data = useMemo(() => localizations ?? [], [localizations]);
  const columns: TableColumns<LoyaltyTierLocalization> = useMemo(
    () => [
      {
        id: 'culture.id',
        accessor: ({ culture }) => culture?.id,
        Header: <TransTableHead i18nKey="language" />,
        type: 'select',
        editableProps: { options: languageOptions, required: false },
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
    ],
    []
  );

  const { form } = useForm<{ rows: Array<LoyaltyTierLocalization> }>({
    initialValues: {
      rows: data ?? [],
    },
  });

  const table = useFormTable(
    {
      data,
      columns,
      form,
      onRowUpdate: async ({ culture, ...rest }) => {
        try {
          await dispatch(
            updateOrCreateLoyaltyProgramTierLocalization({
              ...rest,
              tierId: tier.id,
              cultureId: culture.id,
            })
          );
          await dispatch(getLoyaltyTierLocalizations(id));
        } catch {}
      },
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: LoyaltyTierLocalization[]) => {
      await dispatch(
        deleteLoyaltyProgramTierLocalizations({
          tierId: id,
          localizations: payload,
        })
      );
      await dispatch(getLoyaltyTierLocalizations(id));
    },
    [dispatch, id]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 3, p: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1, ml: 2, mr: 2 }}
        >
          <Typography variant="paragraph" fontWeight="600">
            Localizations
          </Typography>
          <div>
            <Button
              variant="text"
              onClick={removeSelectedRows}
              startIcon={<Icon name="delete" />}
            >
              <TransButton i18nKey="deleteSelected" />
            </Button>
            <Button
              variant="text"
              onClick={addRow}
              startIcon={<Icon name="plus" />}
            >
              <TransButton i18nKey="addNew" />
            </Button>
          </div>
        </Stack>
        <Table
          getHeaderProps={{
            style: {
              backgroundColor: 'white',
            },
          }}
          getTableProps={{
            sx: {
              tableLayout: 'fixed',
            },
          }}
          table={table}
        />
      </Box>
    </FormProvider>
  );
};
