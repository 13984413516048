import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Button, Icon, SelectField, TextField } from '@fleet/shared';
import { Link } from 'react-router-dom';
import { FindCustomerProfilesModal } from './FindCustomerProfilesModal';
import {
  EmployeeAccount,
  CustomerProfile,
} from 'dto/businessCustomerContracts';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { useAreaCodes } from 'hooks/useAreaCodes';
import { TransField } from 'i18n/trans/field';

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>;

interface NewCustomerProfileStateProps {
  setFormVisible: StateSetter<boolean>;
}

interface DefaultStateProps {
  setProfileFormVisible: StateSetter<boolean>;
  setLinkFormVisible: StateSetter<boolean>;
}

interface LinkingStateProps {
  setLinkFormVisible: StateSetter<boolean>;
  setDefaultStateVisible: StateSetter<boolean>;
  employeeAccount?: EmployeeAccount;
  updateAfterLinking: (profile: CustomerProfile) => void;
  unlinkProfile: () => void;
  getCustomerProfileFormState: () => CustomerProfile;
}

interface LinkCustomerProfileSectionProps {
  employeeAccount?: EmployeeAccount;
  updateAfterLinking: (profile: CustomerProfile) => void;
  unlinkProfile: () => void;
  getCustomerProfileFormState: () => CustomerProfile;
}

const DefaultState: FC<DefaultStateProps> = ({
  setProfileFormVisible,
  setLinkFormVisible,
}) => {
  return (
    <>
      <Typography variant="subtitle" fontWeight="700">
        <TransSubtitle i18nKey="linkCustomerProfile" />
      </Typography>
      <Grid
        container
        sx={{ alignItems: 'center', mt: 3, mb: 3 }}
        spacing={2}
        columns={4}
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => setProfileFormVisible(true)}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="createNewProfile" />
        </Button>
        <Button
          onClick={() => setLinkFormVisible(true)}
          variant="text"
          color="primary"
          startIcon={<Icon name="link" />}
        >
          <TransButton i18nKey="linkExistingProfile" />
        </Button>
      </Grid>
    </>
  );
};

const NewCustomerProfileState: FC<NewCustomerProfileStateProps> = ({
  setFormVisible,
}) => {
  const areaCodes = useAreaCodes();

  return (
    <>
      <Grid
        container
        sx={{ alignItems: 'center', mt: 3, mb: 3 }}
        spacing={0}
        columns={3}
      >
        <Grid item xs={2}>
          <Typography variant="subtitle" fontWeight="700">
            <TransSubtitle i18nKey="linkCustomerProfile" />
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="text"
            color="primary"
            sx={{ float: 'right' }}
            onClick={() => setFormVisible(false)}
            startIcon={<Icon name="remove-circle" />}
          >
            <TransButton i18nKey="cancelLinkingNewCustomer" />
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ alignItems: 'center', mt: 3, mb: 3 }}
        spacing={2}
        columns={3}
      >
        <Grid item xs={1}>
          <TextField
            required
            name="customerProfile.firstName"
            label={<TransField i18nKey="firstName" />}
          />
        </Grid>

        <Grid item xs={1}>
          <TextField
            required
            name="customerProfile.lastName"
            label={<TransField i18nKey="lastName" />}
          />
        </Grid>

        <Grid item xs={1}>
          <TextField
            required
            name="customerProfile.email"
            label={<TransField i18nKey="email" />}
          />
        </Grid>

        <Grid item xs={1}>
          <Grid
            container
            sx={{ alignItems: 'flex-end' }}
            spacing={3}
            columns={6}
          >
            <Grid item xs={3}>
              <SelectField
                options={areaCodes}
                name="customerProfile.phoneAreaCode"
                label={<TransField i18nKey="phone" />}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField name="customerProfile.phoneNumber" label="" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const LinkingState: FC<LinkingStateProps> = ({
  setLinkFormVisible,
  employeeAccount,
  setDefaultStateVisible,
  updateAfterLinking,
  unlinkProfile,
  getCustomerProfileFormState,
}) => {
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [employeeAcc, setEmployeeAcc] = useState<CustomerProfile | undefined>(
    undefined
  );
  const areaCodes = useAreaCodes();

  const onSubmit = useCallback(
    (customerProfile: CustomerProfile) => {
      setEmployeeAcc(customerProfile);
      updateAfterLinking(customerProfile);
    },
    [updateAfterLinking]
  );

  useEffect(() => {
    if (employeeAccount) {
      setEmployeeAcc({
        id: employeeAccount?.customerProfile?.id,
        email: employeeAccount?.customerProfile?.email,
        firstName: employeeAccount?.customerProfile?.firstName,
        lastName: employeeAccount?.customerProfile?.lastName,
        phoneNumber: employeeAccount?.customerProfile.phoneNumber,
        phoneAreaCode: employeeAccount.customerProfile.phoneAreaCode,
      });
    }
  }, [employeeAccount]);

  return (
    <>
      <Grid
        container
        sx={{ alignItems: 'center', mt: 2, mb: 2 }}
        spacing={0}
        columns={4}
      >
        <Grid item xs={2}>
          <Typography variant="subtitle" fontWeight="700">
            <TransSubtitle
              i18nKey={
                employeeAcc ? 'linkedCustomerProfile' : 'linkCustomerProfile'
              }
            />
          </Typography>
          {employeeAcc && getCustomerProfileFormState() && (
            <Button
              variant="text"
              color="primary"
              startIcon={<Icon name="link" />}
            >
              <Link
                target="_blank"
                to={`/customer_profiles/${employeeAcc?.id}`}
              >
                {employeeAcc?.firstName + '' + employeeAcc?.lastName}
              </Link>
            </Button>
          )}
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{ float: 'right' }}
            variant="text"
            color="primary"
            onClick={() => {
              unlinkProfile();
              setEmployeeAcc(undefined);
              setDefaultStateVisible(true);
              setLinkFormVisible(false);
            }}
            startIcon={<Icon name="remove-circle" />}
          >
            <TransButton
              i18nKey={
                employeeAcc
                  ? 'unlinkCustomerProfile'
                  : 'cancelLinkingExistingProfile'
              }
            />
          </Button>
          <Button
            sx={{ float: 'right' }}
            variant="contained"
            onClick={() => setShouldShowModal(true)}
            startIcon={<Icon name="search" />}
            label={<TransField i18nKey="findCustomerProfileToLink" />}
          />
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ alignItems: 'center', mt: 2, mb: 3 }}
        spacing={2}
        columns={3}
      >
        <Grid item xs={1} sx={{ display: 'none' }}>
          <TextField
            disabled
            name="customerProfile.id"
            label={<TransField i18nKey="name" />}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            disabled
            name="customerProfile.firstName"
            label={<TransField i18nKey="name" />}
          />
        </Grid>

        <Grid item xs={1}>
          <TextField
            disabled
            name="customerProfile.lastName"
            label={<TransField i18nKey="lastName" />}
          />
        </Grid>

        <Grid item xs={1}>
          <TextField
            disabled
            name="customerProfile.email"
            label={<TransField i18nKey="email" />}
          />
        </Grid>

        <Grid item xs={1}>
          <Grid
            container
            sx={{ alignItems: 'flex-end' }}
            spacing={3}
            columns={6}
          >
            <Grid item xs={3}>
              <SelectField
                disabled
                options={areaCodes}
                name="customerProfile.phoneAreaCode"
                label={<TransField i18nKey="phone" />}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField disabled name="customerProfile.phoneNumber" label="" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FindCustomerProfilesModal
        isOpen={shouldShowModal}
        onClose={() => setShouldShowModal(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

export const LinkCustomerProfileSection: FC<LinkCustomerProfileSectionProps> =
  ({
    employeeAccount,
    updateAfterLinking,
    unlinkProfile,
    getCustomerProfileFormState,
  }) => {
    const [shouldShowNewProfileForm, setShouldShowNewProfileForm] =
      useState(false);
    const [shouldShowLinkForm, setShouldShowLinkForm] = useState(false);
    const [shouldShowDefaultState, setShouldShowDefaultState] = useState(false);

    const Content = () =>
      useMemo(() => {
        if (shouldShowNewProfileForm) {
          return (
            <NewCustomerProfileState
              setFormVisible={setShouldShowNewProfileForm}
            />
          );
        }
        if (
          (employeeAccount?.customerProfile && !shouldShowDefaultState) ||
          shouldShowLinkForm
        ) {
          return (
            <LinkingState
              setLinkFormVisible={setShouldShowLinkForm}
              setDefaultStateVisible={setShouldShowDefaultState}
              employeeAccount={employeeAccount}
              updateAfterLinking={updateAfterLinking}
              unlinkProfile={unlinkProfile}
              getCustomerProfileFormState={getCustomerProfileFormState}
            />
          );
        }
        return (
          <DefaultState
            setLinkFormVisible={setShouldShowLinkForm}
            setProfileFormVisible={setShouldShowNewProfileForm}
          />
        );
      }, []);

    return <Content />;
  };
