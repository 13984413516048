import { FC, useCallback, useMemo } from 'react';
import {
  Icon,
  Loadable,
  SearchResult,
  Table,
  TableColumns,
} from '@fleet/shared';
import { Button, CardContent, Stack, Typography } from '@mui/material';

import { NavLink } from 'react-router-dom';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Row, usePagination, useTable } from 'react-table';
import { formatDate } from '@fleet/shared/utils/date';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerContractsTableLoadingSelector } from 'features/loading/loadingSelectors';
import {
  businessCustomerContractsFiltersSelector,
  businessCustomerContractsSelector,
} from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { BusinessCustomerContract } from 'dto/businessCustomerContracts';
import { TransTableHead } from 'i18n/trans/table';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { getBusinessCustomerContracts } from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { TransButton } from 'i18n/trans/button';
import { getContractReport } from 'features/businessCustomerContracts/businessCustomerContractService';

export const BusinessCustomerContractsTable: FC = () => {
  const contracts = useSelector(businessCustomerContractsSelector);
  const loading = useSelector(businessCustomerContractsTableLoadingSelector);
  const filter = useSelector(businessCustomerContractsFiltersSelector);
  const dispatch = useDispatch();
  const data = useMemo(() => contracts?.items ?? [], [contracts]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (contracts) {
        const { limit = pageSize, offset } = contracts;
        return offset / limit;
      }
      return 0;
    },
    [contracts]
  );

  const columns = useMemo<TableColumns<BusinessCustomerContract>>(
    () => [
      {
        accessor: 'contractNumber',
        Header: <TransTableHead i18nKey="contractNumber" />,
        Cell: ({ row: { original } }) => (
          <NavLink
            to={`/customers/business_customer_contracts/edit/${original.businessCustomer.id}/${original.id}`}
          >
            {original.contractNumber}
          </NavLink>
        ),
      },
      {
        id: 'businessCustomer',
        accessor: ({ businessCustomer }) => businessCustomer?.name,
        Header: <TransTableHead i18nKey="businessCustomer" />,
        Cell: ({
          row: { original },
        }: {
          row: Row<BusinessCustomerContract>;
        }) => (
          <NavLink
            to={`/customers/business_customers/edit/${original.businessCustomer.id}`}
          >
            {original.businessCustomer?.name}
          </NavLink>
        ),
      },
      {
        id: 'contractor',
        accessor: ({ contractor }) => contractor?.name,
        Header: <TransTableHead i18nKey="contractor" />,
      },
      {
        accessor: 'referenceNumber',
        Header: <TransTableHead i18nKey="referenceNumber" />,
      },
      {
        id: 'credit.limit',
        accessor: ({ credit }) => credit?.availableLimit,
        Header: <TransTableHead i18nKey="availableLimit" />,
      },
      {
        id: 'validity.from',
        accessor: ({ validity }) => formatDate(validity?.from),
        Header: <TransTableHead i18nKey="startDate" />,
      },
      {
        id: 'validity.to',
        accessor: ({ validity }) => formatDate(validity?.to),
        Header: <TransTableHead i18nKey="endDate" />,
      },
      {
        id: 'status',
        accessor: ({ status }) => status.name,
        Header: <TransTableHead i18nKey="status" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getBusinessCustomerContracts({ ...filter, ...paginationParams })
      ),
    [dispatch, filter]
  );

  const table = useTable(
    {
      data,
      columns,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: contracts?.totalCount,
    },
    usePagination
  );

  return (
    <Loadable loading={loading}>
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <>
              <CardContent sx={{ padding: '16px 24px' }}>
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle" fontWeight="700">
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 2 }}
                  >
                    <TransSubtitle
                      i18nKey="businessCustomerContractsQty"
                      values={{ num: data.length }}
                    />
                  </Typography>
                  <Button
                    startIcon={<Icon name="upload" />}
                    sx={{ ml: 'auto', px: 1, py: 0 }}
                    onClick={async () => await getContractReport(filter)}
                  >
                    <TransButton i18nKey="exportSelected" />
                  </Button>
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
      </SearchResult>
    </Loadable>
  );
};
