import {
  DateField,
  Paper,
  Button,
  SelectField,
  formSubmit,
} from '@fleet/shared';
import { Stack, Grid, CardContent } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { Icon } from '@fleet/shared/mui';
import { useForm, FormProvider, TextField } from '@fleet/shared';
import {
  DistanceConversionRateException,
  LineTemplate,
  ServiceCode,
} from 'dto/loyaltyProgram';
import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'store/utils';
import {
  lineSelector,
  placeClassesSelector,
} from 'features/loyaltyProgram/loyaltyProgramSelectors';
import {
  getLineTemplates,
  getServiceCodes,
  updateOrCreateDistanceConversionRateException,
  getDistanceConversionRateExceptions,
  deleteDistanceConversionRateException,
} from 'features/loyaltyProgram/loyaltyProgramActions';
import { transactionTypeOptions } from 'consts';

interface ServiceLevelFormProps {
  data?: DistanceConversionRateException;
  closeForm?: () => void;
}

export const DistanceConversionRateExceptionForm: FC<ServiceLevelFormProps> = ({
  data,
  closeForm,
}) => {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (formValues: DistanceConversionRateException) =>
      formSubmit(async () => {
        const { lineTemplate, transactionType, placeClass, ...rest } =
          formValues;

        try {
          await dispatch(
            updateOrCreateDistanceConversionRateException({
              ...rest,
              lineTemplateId: lineTemplate?.id,
              placeClassId: placeClass?.id,
              transactionTypeId: transactionType?.id,
            })
          ).unwrap();
          await dispatch(getDistanceConversionRateExceptions()).unwrap();

          if (closeForm) {
            closeForm();
          }
        } catch {
          console.log('Service Level PUT/POST failed');
        }
      }),
    [dispatch, closeForm]
  );
  const initialValues = useMemo(() => ({ ...data } ?? {}), [data]);

  const { form, handleSubmit, values } =
    useForm<DistanceConversionRateException>({
      initialValues,
      onSubmit,
      subscription: { values: true },
    });

  const lines = useSelector(lineSelector);
  const placeClasses = useSelector(placeClassesSelector);

  const [lineTemplateIds, setLineTemplateIds] = useState<Array<LineTemplate>>(
    []
  );
  const [serviceCodes, setServiceCodes] = useState<Array<ServiceCode>>([]);

  const { line, lineTemplate, serviceCode } = values;

  const {
    ['line.id']: dirtyLineId,
    ['lineTemplate.id']: dirtyLineTemplateId,
    serviceCode: dirtyServiceCode,
  } = form.getState().dirtyFields;

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getLineTemplates(line.id)).unwrap();
      setLineTemplateIds(data ?? []);
    };

    if (line?.id) {
      if (dirtyLineId) {
        form.change('lineTemplate');
        form.change('serviceCode');
        form.change('departureTime');
        setServiceCodes([]);
      }

      fetchData();
    }
  }, [dispatch, line, dirtyLineId, form]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getServiceCodes(lineTemplate.id)).unwrap();
      setServiceCodes(data ?? []);
    };

    if (lineTemplate?.id) {
      if (dirtyLineTemplateId) {
        form.change('serviceCode');
        form.change('departureTime');
        setServiceCodes([]);
      }

      fetchData();
    }
  }, [dispatch, lineTemplate, dirtyLineTemplateId, form]);

  useEffect(() => {
    if (serviceCode) {
      if (dirtyServiceCode) {
        form.change('departureTime');
      }
    }
  }, [serviceCode, dirtyServiceCode, form]);

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <Paper sx={{ mb: 2, backgroundColor: '#F6F6F6' }}>
      <Stack
        sx={{ p: 2 }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Stack>
          <Button
            disabled={!data}
            variant="text"
            onClick={async () => {
              await dispatch(deleteDistanceConversionRateException(data!));
              await dispatch(getDistanceConversionRateExceptions());
            }}
            startIcon={<Icon name="delete" />}
          >
            <TransButton i18nKey="delete" />
          </Button>
        </Stack>
      </Stack>
      <FormProvider {...form}>
        <CardContent component="form" onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={3}>
            <Grid item xs={1}>
              <SelectField
                name="line.id"
                label="Line "
                options={lines.map((l) => ({
                  value: l.id,
                  label: l.name,
                }))}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="lineTemplate.id"
                label="Line Template "
                options={lineTemplateIds.map((t) => ({
                  value: t.id,
                  label: t.name,
                }))}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="serviceCode"
                label="Service Code "
                options={serviceCodes.map((s) => ({
                  value: s.code!,
                  label: s.code,
                }))}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="departureTime"
                label="Departure time "
                options={serviceCodes
                  .filter((s) => s.code === serviceCode)
                  .map((s) => ({
                    value: s.departureTime!,
                    label: s.departureTime,
                  }))}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="placeClass.id"
                label="Place class "
                showEmptyOption
                options={placeClasses.map((p) => ({
                  value: p.id,
                  label: p.name,
                }))}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField name="distanceToPointsRate" label="Distance Rate " />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="distanceToCarbonDioxideGramsRate"
                label="CO2 rate"
              />
            </Grid>
            <Grid item xs={1}>
              <DateField name="validity.from" label="Valid From" />
            </Grid>
            <Grid item xs={1}>
              <DateField name="validity.to" label="Valid to" />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="transactionType.id"
                label="Transaction type"
                options={transactionTypeOptions}
              />
            </Grid>
            <Grid item xs={5} sx={{ alignItems: 'flex-end' }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                <Button variant="text" onClick={handleReset}>
                  <TransButton i18nKey="resetFields" />
                </Button>
                <Button icon="check" type="submit" sx={{ ml: 2 }}>
                  <TransButton i18nKey="save" />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </FormProvider>
    </Paper>
  );
};
