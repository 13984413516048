import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Drawer,
  FormProvider,
  Icon,
  Loadable,
  SelectField,
  Tooltip,
  useForm,
} from '@fleet/shared';
import {
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { RadioGroupField, TextField } from '@fleet/shared/form';
import { CardHeader } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerUnitsLoadingSelector } from 'features/loading/loadingSelectors';
import {
  businessCustomerUnitSelector,
  currentBusinessCustomerSelector,
  usersForOrganizationsSelector,
} from 'features/businessCustomer/businessCustomerSelectors';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  getBusinessCustomerUnits,
  getBusinessCustomerUnitsById,
  getUsersForOrganization,
  setBusinessCustomerUnit,
  updateOrCreateBusinessCustomerUnits,
} from 'features/businessCustomer/businessCustomerActions';
import { BusinessCustomerUnitRequest } from 'dto/businessCustomer';
import { TransAlert } from 'i18n/trans/alert';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';

const useStyles = makeStyles(
  () => ({
    action: { alignSelf: 'flex-start' },
    field: { marginBottom: 16 },
  }),
  {
    name: 'UnitsDrawerForm',
  }
);

export const UnitsDrawerForm: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { action, entityId } =
    useParams<{ action: 'create' | 'edit'; entityId: string }>();
  const classes = useStyles();
  const loading = useSelector(businessCustomerUnitsLoadingSelector);
  const businessCustomer = useSelector(currentBusinessCustomerSelector);
  const countries = useClassificationOptions(ClassificationGroup.COUNTRY);
  const counties = useClassificationOptions(ClassificationGroup.COUNTY);
  const cities = useClassificationOptions(ClassificationGroup.CITY);
  const unit = useSelector(businessCustomerUnitSelector);
  const customerManagers =
    useSelector(usersForOrganizationsSelector)?.map((customerManager) => ({
      value: customerManager.id,
      label: customerManager.fullName,
    })) ?? [];
  const alert = useAlert();
  const isEditing = action === 'edit';

  useEffect(() => {
    dispatch(setBusinessCustomerUnit());

    if (businessCustomer?.owner.id) {
      dispatch(getUsersForOrganization(businessCustomer.owner.id));
    }

    if (isEditing && entityId) {
      dispatch(getBusinessCustomerUnitsById(entityId));
    }
  }, [dispatch, entityId, isEditing, businessCustomer?.owner.id]);

  const closeDrawer = useCallback(() => {
    history.replace(
      `/customers/business_customers/edit/${businessCustomer?.id}`
    );
    dispatch(setBusinessCustomerUnit());
  }, [businessCustomer?.id, dispatch, history]);

  const onSubmit = useCallback(
    async (values: BusinessCustomerUnitRequest) => {
      await dispatch(updateOrCreateBusinessCustomerUnits(values)).unwrap();
      alert.success(
        <TransAlert
          i18nKey={
            values?.id
              ? 'businessCustomerUnitUpdated'
              : 'businessCustomerUnitCreated'
          }
        />
      );
      dispatch(getBusinessCustomerUnits());
      closeDrawer();
    },
    [dispatch, alert, closeDrawer]
  );

  const initialValues = useMemo(() => {
    if (!unit) {
      return {};
    }

    const { customerManager, address, ...rest } = unit;

    return {
      ...rest,
      customerManagerId: customerManager?.id,
      address: {
        ...address,
        cityId: address.city?.id,
        countryId: address.country.id,
        countyId: address.county?.id,
      },
    };
  }, [unit]);

  const { form, submitting, handleSubmit } = useForm({
    onSubmit,
    initialValues,
  });

  return (
    <Drawer anchor="right" elevation={0} onClose={closeDrawer} open>
      <Loadable loading={loading}>
        <FormProvider {...form}>
          <Stack
            sx={{
              height: '100%',
              width: '424px !important',
              minWidth: '0 !important',
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            <CardHeader
              sx={{ pb: 0 }}
              classes={{ action: classes.action }}
              title={
                <Typography variant="subtitle" component="div">
                  {isEditing ? (
                    <TransSubtitle i18nKey="editBusinessCustomerUnit" />
                  ) : (
                    <TransSubtitle i18nKey="newBusinessCustomerUnit" />
                  )}
                </Typography>
              }
              action={
                <IconButton aria-label="close" onClick={closeDrawer}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              }
            />
            <CardContent sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
              <Stack sx={{ mt: 2 }} direction="column" spacing={2}>
                <TextField
                  required
                  name="name"
                  label={<TransField i18nKey="name" />}
                />
                <TextField
                  name="phone"
                  label={<TransField i18nKey="phone" />}
                />
                <TextField
                  name="email"
                  label={<TransField i18nKey="email" />}
                />
                <SelectField
                  options={customerManagers}
                  name="customerManagerId"
                  label={<TransField i18nKey="clientManager" />}
                />
                <SelectField
                  options={countries}
                  required
                  name="address.countryId"
                  label={<TransField i18nKey="country" />}
                />
                <SelectField
                  options={counties}
                  name="address.countyId"
                  label={<TransField i18nKey="county" />}
                />
                <SelectField
                  options={cities}
                  name="address.cityId"
                  label={<TransField i18nKey="city" />}
                />
                <TextField
                  name="address.zipCode"
                  label={<TransField i18nKey="zipCode" />}
                />
                <TextField
                  required
                  name="address.streetAddress"
                  label={<TransField i18nKey="streetHouseNumber" />}
                />
                <TextField
                  name="comment"
                  label={<TransField i18nKey="comment" />}
                />
                <RadioGroupField
                  required
                  name="isActive"
                  label={<TransField i18nKey="isActive" />}
                  options="BOOL_ONLY"
                  inline
                />
              </Stack>
            </CardContent>
            <CardActions
              sx={{
                padding: 3,
                justifyContent: 'flex-end',
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Button variant="text" color="primary" onClick={closeDrawer}>
                <TransButton i18nKey="cancel" />
              </Button>
              {entityId ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<Icon name="check" />}
                >
                  <TransButton i18nKey="save" disabled={submitting} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                  startIcon={<Icon name="plus" />}
                >
                  <TransButton i18nKey="add" />
                </Button>
              )}
            </CardActions>
          </Stack>
        </FormProvider>
      </Loadable>
    </Drawer>
  );
};
