import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  ExternalLink,
  FormProvider,
  formSubmit,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { CardContent, Grid, Stack } from '@mui/material';

import { useAuth } from 'react-oidc-context';
import { BusinessCustomerRequest } from 'dto/businessCustomer';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { useHistory } from 'react-router-dom';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  currentBusinessCustomerSelector,
  usersForOrganizationsSelector,
} from 'features/businessCustomer/businessCustomerSelectors';
import { businessCustomerFormCustomerManagersLoadingSelector } from 'features/loading/loadingSelectors';
import { useAlert } from 'react-alert';
import {
  getBusinessCustomerById,
  getBusinessCustomers,
  getUsersForOrganization,
  updateOrCreateBusinessCustomer,
} from 'features/businessCustomer/businessCustomerActions';
import { TransAlert } from 'i18n/trans/alert';
import { TransField } from 'i18n/trans/field';
import { FormInputLabel } from 'components/FormLabel';
import { TransButton } from 'i18n/trans/button';

interface BusinessCustomerFormProps {}

export const BusinessCustomerForm: FC<BusinessCustomerFormProps> = () => {
  const auth = useAuth();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const businessCustomer = useSelector(currentBusinessCustomerSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const businessEntities = useClassificationOptions(
    ClassificationGroup.BUSINESS_ENTITY
  );
  const statuses = useClassificationOptions(
    ClassificationGroup.ORGANIZATION_STATUS
  );
  const countries = useClassificationOptions(ClassificationGroup.COUNTRY);
  const cities = useClassificationOptions(ClassificationGroup.CITY);
  const counties = useClassificationOptions(ClassificationGroup.COUNTY);
  const customerManagers = useSelector(usersForOrganizationsSelector)?.map(
    (customerManager) => ({
      value: customerManager.id,
      label: customerManager.fullName,
    })
  );
  const customerManagerLoading = useSelector(
    businessCustomerFormCustomerManagersLoadingSelector
  );
  const alert = useAlert();

  const initialValues = useMemo(() => {
    if (!businessCustomer) {
      return {
        ownerId: currentBusinessEntityId,
      };
    }

    const { owner, status, customerManager, legalAddress, ...rest } =
      businessCustomer;

    return {
      ...rest,
      ownerId: owner.id,
      statusId: status?.id,
      customerManagerId: customerManager?.id,
      legalAddress: {
        countryId: legalAddress.country.id,
        countyId: legalAddress.county?.id,
        cityId: legalAddress.city?.id,
        zipCode: legalAddress.zipCode,
        streetAddress: legalAddress.streetAddress,
      },
    };
  }, [businessCustomer, currentBusinessEntityId]);

  useEffect(() => {
    dispatch(getUsersForOrganization(initialValues.ownerId));
  }, [dispatch, initialValues.ownerId]);

  const onSubmit = useCallback(
    (values: BusinessCustomerRequest) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        const data = await dispatch(
          updateOrCreateBusinessCustomer(values)
        ).unwrap();

        alert.success(
          <TransAlert
            i18nKey={
              values.id ? 'businessCustomerUpdated' : 'businessCustomerCreated'
            }
          />
        );

        if (!values.id) {
          history.push(`edit/${data.id}`);
        }

        dispatch(getBusinessCustomerById(data.id));
        dispatch(getBusinessCustomers());
      }),
    [alert, dispatch, history]
  );

  const { form, handleSubmit } = useForm<BusinessCustomerRequest>({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <CardContent sx={{ p: '24px 24px' }}>
          <Grid container sx={{ alignItems: 'center' }} spacing={2} columns={3}>
            <Grid item xs={1}>
              {businessCustomer ? (
                <>
                  <FormInputLabel label={<TransField i18nKey="owner" />} />
                  <ExternalLink
                    path={`/Contacts/Organization/Edit/${businessCustomer.owner.id}`}
                    content={`${businessCustomer.owner.name} (${businessCustomer.owner.id})`}
                  />
                </>
              ) : (
                <SelectField
                  required
                  name="ownerId"
                  label={<TransField i18nKey="owner" />}
                  options={businessEntities}
                  onChange={(value) => {
                    dispatch(getUsersForOrganization(+value));
                  }}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              <SelectField
                loading={customerManagerLoading}
                name="customerManagerId"
                label={<TransField i18nKey="clientManager" />}
                options={customerManagers ?? []}
                {...(!businessCustomer && {
                  defaultValue: customerManagers?.find(
                    (manager) => manager.label === auth.user?.profile.full_name
                  )?.value,
                })}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required
                name="name"
                label={<TransField i18nKey="businessCustomerName" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="referenceNumber"
                label={<TransField i18nKey="referenceNumber" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="registrationCode"
                label={<TransField i18nKey="registrationCode" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="vatCode"
                label={<TransField i18nKey="vatCode" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                required
                name="legalAddress.countryId"
                label={<TransField i18nKey="country" />}
                options={countries}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="legalAddress.cityId"
                label={<TransField i18nKey="city" />}
                options={cities}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required
                name="legalAddress.streetAddress"
                label={<TransField i18nKey="streetHouseNumber" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="email"
                type="email"
                label={<TransField i18nKey="email" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                inputProps={{ minLength: 6 }}
                name="phoneNumber"
                label={<TransField i18nKey="phone" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="legalAddress.countyId"
                label={<TransField i18nKey="county" />}
                options={counties}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                type="email"
                name="invoiceEmail"
                label={<TransField i18nKey="invoiceEmail" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="legalAddress.zipCode"
                label={<TransField i18nKey="zipCode" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="webPage"
                label={<TransField i18nKey="webPage" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="statusId"
                label={<TransField i18nKey="status" />}
                options={statuses}
              />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', mt: 3 }}>
              <Stack
                direction="row"
                sx={{ ml: 'auto', alignItems: 'center' }}
                spacing={1}
              >
                <Button onClick={handleReset} variant="text">
                  <TransButton i18nKey="resetChanges" />
                </Button>
                <Button icon="check" type="submit" sx={{ ml: 2 }}>
                  <TransButton i18nKey="save" />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </FormProvider>
  );
};
