import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  PeriodField,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Box, Grid, Stack } from '@mui/material';

import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerContractsFiltersSelector } from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { BusinessCustomerContractsFilter } from 'dto/businessCustomerContracts';
import {
  getBusinessCustomerContracts,
  setBusinessCustomerContractsFilter,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

export const BusinessCustomerContractsSearch: FC = () => {
  const organizationStatus = useClassificationOptions(
    ClassificationGroup.CLIENT_CONTRACT_STATUS
  );
  const dispatch = useDispatch();
  const businessEntities = useClassificationOptions(
    ClassificationGroup.BUSINESS_ENTITY
  );
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(businessCustomerContractsFiltersSelector);

  const onSubmit = useCallback(
    (values: BusinessCustomerContractsFilter) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getBusinessCustomerContracts({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const initialValues = useMemo(
    () => ({ contractorId: currentBusinessEntityId, ...filter }),
    [currentBusinessEntityId, filter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setBusinessCustomerContractsFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit } = useForm({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setBusinessCustomerContractsFilter({}));
  }, [form, dispatch]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ alignItems: 'center' }} spacing={2} columns={4}>
            <Grid item xs={1}>
              <SelectField
                name="contractorId"
                label={<TransField i18nKey="contractor" />}
                options={businessEntities}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="statusIds"
                label={<TransField i18nKey="status" />}
                options={organizationStatus}
                multiple
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="businessCustomerName"
                label={<TransField i18nKey="businessCustomerName" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="employeeAccountNumber"
                label={<TransField i18nKey="accountNumber" />}
              />
            </Grid>
            <PeriodField
              from={{
                name: 'validityStartFrom',
                label: <TransField i18nKey="validFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'validityStartTo',
                isClearable: true,
              }}
            />
            <PeriodField
              from={{
                name: 'validityEndFrom',
                label: <TransField i18nKey="validUntil" />,
                isClearable: true,
              }}
              to={{
                name: 'validityEndTo',
                isClearable: true,
              }}
            />
            <Grid item xs={1}>
              <TextField
                name="referenceNumber"
                label={<TransField i18nKey="referenceNumber" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="contractNumber"
                label={<TransField i18nKey="contractNumber" />}
              />
            </Grid>
            <Grid item xs={1}>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <TextField
                  name="limitFrom"
                  label={<TransField i18nKey="limitFrom" />}
                />
                <Box sx={{ padding: '20px 8px 0px 8px' }}>—</Box>

                <TextField
                  name="limitTo"
                  label={<TransField i18nKey="limitTo" />}
                />
              </Stack>
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFields" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
