import { useSelector } from 'store/utils';
import { countriesSelector } from 'features/classification/classificationSelectors';
import _ from 'lodash';

export const useAreaCodes = () => {
  const areaCodes = useSelector(countriesSelector).map((country) => {
    return {
      value: country.phoneAreaCode,
      label: country.phoneAreaCode,
    };
  });
  return _.chain(areaCodes).sortBy('label').uniqBy('label').value();
};
