import {
  Checkbox,
  ExternalLink,
  Loadable,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { CustomerProfileTravelPasses } from 'dto/customerProfile';
import { useDispatch, useSelector } from 'store/utils';
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  deleteCustomerProfileTravelPass,
  getCustomerProfileTravelPasses,
} from 'features/customerProfile/customerProfileActions';
import {
  Row,
  useFilters,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { TransTableHead } from 'i18n/trans/table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import {
  customerProfileTravelPassesSelector,
  customerProfileTravelPassFilterSelector,
} from 'features/customerProfile/customerProfileSelectors';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { customerTravelPassTableLoadingSelector } from 'features/loading/loadingSelectors';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { ConnectTravelPassModal } from 'routes/customerProfile/ConnectTravelPassModal';

export const TravelPasses: FunctionComponent = () => {
  const [isConnectTravelPassModalOpen, setIsConnectTravelPassModalOpen] =
    useState(false);
  const filter = useSelector(customerProfileTravelPassFilterSelector);
  const travelPasses = useSelector(customerProfileTravelPassesSelector);
  const data = useMemo(() => travelPasses?.items ?? [], [travelPasses]);
  const loading = useSelector(customerTravelPassTableLoadingSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerProfileTravelPasses());
  }, [dispatch]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (travelPasses) {
        const { limit = pageSize, offset } = travelPasses;
        return offset / limit;
      }
      return 0;
    },
    [travelPasses]
  );

  const columns = useMemo<TableColumns<CustomerProfileTravelPasses>>(
    () => [
      {
        id: 'number',
        accessor: 'number',
        Header: <TransTableHead i18nKey="travelPassNumber" />,
        Cell: ({ row }: { row: Row<CustomerProfileTravelPasses> }) => (
          <ExternalLink
            path={`/Trips/TravelPass/Edit/${row.original.id}`}
            content={row.original.number}
          />
        ),
      },
      {
        id: 'travelProduct.id',
        accessor: ({ travelProduct }) => travelProduct?.name,
        Header: <TransTableHead i18nKey="travelProduct" />,
        Cell: ({ row }: { row: Row<CustomerProfileTravelPasses> }) => (
          <ExternalLink
            path={`/General/TravelServiceDetail/Edit/${row.original.travelProduct.id}`}
            content={row.original.travelProduct.name}
          />
        ),
      },
      {
        id: 'travelPassType.id',
        accessor: ({ travelPassType }) => travelPassType?.name,
        Header: <TransTableHead i18nKey="travelPassType" />,
      },
      {
        id: 'purchasedOn.value',
        accessor: ({ purchasedOn }) =>
          formatDate(purchasedOn?.value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="purchasedOn" />,
      },
      {
        id: 'validUntil.value',
        accessor: ({ validUntil }) =>
          formatDate(validUntil?.value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="validUntil" />,
      },
      {
        id: 'isCancelled',
        accessor: 'isCancelled',
        Header: <TransTableHead i18nKey="cancelled" />,
        Cell: ({
          row: {
            original: { isCancelled },
          },
        }) => isCancelled && <Icon name="check" color="success" />,
      },
      {
        id: 'isActivated',
        accessor: 'isActivated',
        Header: <TransTableHead i18nKey="activated" />,
        Cell: ({
          row: {
            original: { isActivated },
          },
        }) => isActivated && <Icon name="check" color="success" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getCustomerProfileTravelPasses({
          ...paginationParams,
          ...filter,
        })
      ),
    [dispatch, filter]
  );

  const table = useTable(
    {
      data,
      columns,
      initialState: {
        pageSize: 10,
      },
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: travelPasses?.totalCount,
    },
    useFilters,
    useIndeterminateRowSelectCheckbox,
    usePagination,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteCustomerProfileTravelPass(
        table.selectedFlatRows.map((row) => row.original.id)
      )
    );
    await dispatch(getCustomerProfileTravelPasses());
  }, [dispatch, table.selectedFlatRows]);

  const handleIsValidFilterToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      await dispatch(
        getCustomerProfileTravelPasses(
          e.target.checked
            ? {
                isValid: e.target.checked,
              }
            : {}
        )
      );
    },
    [dispatch]
  );

  return (
    <>
      <Loadable loading={loading}>
        <Box sx={{ mb: 6 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <div>
              <Checkbox
                onChange={handleIsValidFilterToggle}
                label={
                  <Typography
                    variant="body2"
                    color="text.primary"
                    component="span"
                  >
                    <TransField i18nKey="showValidTravelPasses" />
                  </Typography>
                }
                size="small"
              />
            </div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 'auto' }}
            >
              <Button
                disabled={!table.selectedFlatRows.length}
                variant="text"
                onClick={onRowsRemove}
                startIcon={<Icon name="delete" />}
                color="error"
              >
                <TransButton i18nKey="removeTravelPasses" />
              </Button>
              <Button
                variant="text"
                onClick={() => setIsConnectTravelPassModalOpen(true)}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="connectTravelPasses" />
              </Button>
            </Stack>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </Loadable>
      <ConnectTravelPassModal
        isOpen={isConnectTravelPassModalOpen}
        onClose={() => setIsConnectTravelPassModalOpen(false)}
      />
    </>
  );
};
