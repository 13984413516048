import React, { FC, useCallback, useMemo } from 'react';
import {
  ExternalLink,
  Loadable,
  SearchResult,
  Table,
  TableColumns,
} from '@fleet/shared';
import { CardContent, Stack, Typography } from '@mui/material';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Row, usePagination, useTable } from 'react-table';
import { Link } from 'react-router-dom';
import {
  businessCustomersFilterSelector,
  businessCustomersSelector,
} from 'features/businessCustomer/businessCustomerSelectors';
import { businessCustomersTableLoadingSelector } from 'features/loading/loadingSelectors';
import { useDispatch, useSelector } from 'store/utils';
import { BusinessCustomer } from 'dto/businessCustomer';
import { TransTableHead } from 'i18n/trans/table';
import { getBusinessCustomers } from 'features/businessCustomer/businessCustomerActions';
import { TransSubtitle } from 'i18n/trans/subtitle';

export const BusinessCustomerTable: FC = () => {
  const profiles = useSelector(businessCustomersSelector);
  const loading = useSelector(businessCustomersTableLoadingSelector);
  const filter = useSelector(businessCustomersFilterSelector);
  const dispatch = useDispatch();
  const data = useMemo(() => profiles?.items ?? [], [profiles]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (profiles) {
        const { limit = pageSize, offset } = profiles;
        return offset / limit;
      }
      return 0;
    },
    [profiles]
  );

  const link = useCallback(
    (row: Row<BusinessCustomer>) =>
      `/customers/business_customers/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<BusinessCustomer>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="businessCustomer" />,
        Cell: ({ row }) => <Link to={link(row)}>{row.original.name}</Link>,
      },
      {
        accessor: 'referenceNumber',
        Header: <TransTableHead i18nKey="referenceNumber" />,
      },
      {
        accessor: 'registrationCode',
        Header: <TransTableHead i18nKey="registrationCode" />,
      },
      {
        id: 'owner.id',
        accessor: ({ owner }) => owner?.id,
        Header: <TransTableHead i18nKey="owner" />,
        Cell: ({ row: { original } }: { row: Row<BusinessCustomer> }) => (
          <ExternalLink
            path={`/Contacts/Organization/Edit/${original.owner.id}`}
            content={original.owner.name}
          />
        ),
      },
      {
        id: 'status',
        accessor: ({ status }) => status?.name,
        Header: <TransTableHead i18nKey="status" />,
      },
    ],
    [link]
  );
  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getBusinessCustomers({ ...filter, ...paginationParams })),
    [dispatch, filter]
  );

  const table = useTable(
    {
      data,
      columns,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: profiles?.totalCount,
    },
    usePagination
  );

  return (
    <Loadable loading={loading}>
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <>
              <CardContent sx={{ padding: '16px 24px' }}>
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle" fontWeight="700">
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 2 }}
                  >
                    <TransSubtitle
                      i18nKey="accountsQty"
                      values={{ num: data.length }}
                    />
                  </Typography>
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
      </SearchResult>
    </Loadable>
  );
};
