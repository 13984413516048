import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ExternalLink,
  FileField,
  FormField,
  FormProvider,
  formSubmit,
  makeClassificationOptions,
  PeriodField,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { CardContent, Divider, Grid, Stack, Typography } from '@mui/material';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { FindBusinessCustomersModal } from '../FindBusinessCustomersModal';
import { useAlert } from 'react-alert';
import { BusinessCustomerContractRequest } from 'dto/businessCustomerContracts';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getBusinessCustomerContractById,
  getBusinessCustomerContracts,
  updateOrCreateBusinessCustomerContracts,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { TransAlert } from 'i18n/trans/alert';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FormInputLabel } from 'components/FormLabel';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { currentBusinessCustomerContractSelector } from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { getBaseDataFile } from 'features/businessCustomerContracts/businessCustomerContractService';
import { Classifier } from '@fleet/shared/dto/classifier';
import { fetchUnits } from 'features/businessCustomer/businessCustomerService';
import { Option } from '@fleet/shared/dto/option';

interface BusinessCustomerContractFormProps {}

export const BusinessCustomerContractForm: FC<BusinessCustomerContractFormProps> =
  () => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();
    const { state: contractDataFromLocationState } = useLocation<
      | {
          businessCustomer: Classifier<number>;
          contractorId: number;
        }
      | undefined
    >();
    const [unitOptions, setUnitOptions] = useState<Array<Option<number>>>([]);
    const businessCustomerContract = useSelector(
      currentBusinessCustomerContractSelector
    );
    const [isBusinessCustomersModalOpen, setIsBusinessCustomersModalOpen] =
      useState(false);
    const currencyOptions = useClassificationOptions(
      ClassificationGroup.CURRENCY
    );
    const creditTypeOptions = useClassificationOptions(
      ClassificationGroup.CREDIT_TYPE
    );
    const clientContractStatusOptions = useClassificationOptions(
      ClassificationGroup.CLIENT_CONTRACT_STATUS
    );
    const businessEntityOptions = useClassificationOptions(
      ClassificationGroup.BUSINESS_ENTITY
    );
    const currentBusinessEntityId = useSelector(
      currentBusinessEntityIdSelector
    );

    const onSubmit = useCallback(
      (values: BusinessCustomerContractRequest) =>
        formSubmit(async () => {
          const formData = new FormData();
          formData.append('File', values.file as unknown as Blob);
          formData.append('ContractorId', String(values.contractorId));
          formData.append('ReferenceNumber', values.referenceNumber);
          formData.append('Validity.From', values.validity.from);
          formData.append('Validity.To', values.validity.to);
          formData.append('Validity.Timezone', values.validity.timeZone);
          formData.append('StatusId', values.statusId);
          values.unitId && formData.append('UnitId', String(values.unitId));
          values.comment && formData.append('Comment', values.comment);
          formData.append('Credit.TypeId', values.credit.typeId);
          formData.append('Credit.Limit', String(values.credit.limit));
          formData.append('Credit.CurrencyId', values.credit.currencyId);
          formData.append(
            'Credit.PaymentTermInDays',
            String(values.credit.paymentTermInDays)
          );
          formData.append('IsFileRemoved', !values.file ? 'true' : 'false');

          (document.activeElement as HTMLInputElement)?.blur?.();
          const data = await dispatch(
            updateOrCreateBusinessCustomerContracts({
              payload: formData,
              id: values.id,
              businessCustomerId: values.businessCustomer?.id,
            })
          ).unwrap();
          alert.success(
            <TransAlert
              i18nKey={
                values.id
                  ? 'businessCustomerContractUpdated'
                  : 'businessCustomerContractCreated'
              }
            />
          );

          if (!values.id) {
            history.push(`edit/${data.businessCustomer.id}/${data.id}`);
          }

          dispatch(
            getBusinessCustomerContractById({
              businessCustomerId: data.businessCustomer.id,
              contractId: data.id,
            })
          );
          dispatch(getBusinessCustomerContracts());
        }),
      [alert, dispatch, history]
    );

    const initialValues: Partial<BusinessCustomerContractRequest> =
      useMemo(() => {
        let values: Partial<BusinessCustomerContractRequest> = {
          contractorId: currentBusinessEntityId,
        };

        if (contractDataFromLocationState) {
          values = contractDataFromLocationState;
        }

        if (businessCustomerContract) {
          const {
            contractor,
            status,
            unit,
            credit: { type, currency, ...restOfCredit },
            ...rest
          } = businessCustomerContract;

          values = {
            ...rest,
            contractorId: contractor.id,
            statusId: status.id,
            unitId: unit?.id,
            comment: businessCustomerContract.comment,
            credit: {
              ...restOfCredit,
              typeId: type.id,
              currencyId: currency.id,
            },
          };
        }

        return values;
      }, [
        businessCustomerContract,
        currentBusinessEntityId,
        contractDataFromLocationState,
      ]);

    const { form, handleSubmit, values } =
      useForm<BusinessCustomerContractRequest>({
        initialValues,
        onSubmit,
        subscription: { values: true },
      });

    useEffect(() => {
      const fetchUnitOptions = async () => {
        setUnitOptions(
          makeClassificationOptions(
            await fetchUnits(values.businessCustomer!.id)
          )
        );
      };

      if (values.businessCustomer?.id) {
        fetchUnitOptions();
      }
    }, [values.businessCustomer]);

    const handleReset = useCallback(() => {
      form.reset();
    }, [form]);

    return (
      <>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <CardContent sx={{ p: '24px 24px' }}>
              <Typography variant="subtitle" fontWeight="700" paragraph mb="2">
                <TransSubtitle i18nKey="businessCustomerSetup" />
              </Typography>
              <Grid
                container
                sx={{ alignItems: 'center' }}
                spacing={2}
                columns={4}
              >
                <Grid item xs={1}>
                  {initialValues?.businessCustomer?.id ? (
                    <>
                      <FormInputLabel
                        label={<TransField i18nKey="businessCustomerName" />}
                      />
                      <NavLink
                        to={`/customers/business_customers/edit/${initialValues?.businessCustomer?.id}`}
                      >
                        {initialValues?.businessCustomer?.name}
                      </NavLink>
                    </>
                  ) : (
                    <TextField
                      required
                      name="businessCustomer.name"
                      label={<TransField i18nKey="businessCustomerName" />}
                      readOnly
                      inputProps={{
                        onClick: () => setIsBusinessCustomersModalOpen(true),
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={1}>
                  <FormField
                    name="contractorId"
                    render={({ input }) => (
                      <>
                        <FormInputLabel
                          label={<TransField i18nKey="contractor" />}
                        ></FormInputLabel>
                        <ExternalLink
                          path={`/Contacts/Organization/Edit/${input.value}`}
                          content={
                            businessEntityOptions.find(
                              ({ value }) => value === input.value
                            )?.label || 'Unknown contractor'
                          }
                        />
                      </>
                    )}
                  ></FormField>
                </Grid>
                <PeriodField
                  from={{
                    name: 'validity.from',
                    label: <TransField i18nKey="validFrom" />,
                    required: true,
                  }}
                  to={{
                    name: 'validity.to',
                    label: <TransField i18nKey="validUntil" />,
                    required: true,
                  }}
                />
                <Grid item xs={1}>
                  <TextField
                    required
                    name="referenceNumber"
                    label={<TransField i18nKey="referenceNumber" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    disabled={!initialValues.businessCustomer?.id}
                    options={unitOptions}
                    name="unitId"
                    label={<TransField i18nKey="customerUnit" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    required
                    name="statusId"
                    label={<TransField i18nKey="status" />}
                    options={clientContractStatusOptions}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="comment"
                    label={<TransField i18nKey="comment" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FileField
                    id="businessContractFileId"
                    name="file"
                    multiple={false}
                    onDownload={(fileName: string) => {
                      initialValues?.file?.guid &&
                        getBaseDataFile({
                          fileId: initialValues.file.guid,
                          fileName: fileName,
                        });
                    }}
                    label={<TransField i18nKey="file" />}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: '32px', marginBottom: '16px' }} />
              <Typography variant="subtitle" fontWeight="700" paragraph mb="2">
                <TransSubtitle i18nKey="creditLimitSetup" />
              </Typography>
              <Grid
                container
                sx={{ alignItems: 'center' }}
                spacing={2}
                columns={4}
              >
                <Grid item xs={1}>
                  <SelectField
                    required
                    name="credit.typeId"
                    label={<TransField i18nKey="creditType" />}
                    options={creditTypeOptions}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required
                    name="credit.limit"
                    label={<TransField i18nKey="creditLimit" />}
                  />
                </Grid>
                {businessCustomerContract &&
                  businessCustomerContract.credit.usedLimit >= 0 && (
                    <Grid item xs={1}>
                      <FormInputLabel
                        label={<TransField i18nKey="usedLimit" />}
                      />
                      {businessCustomerContract?.credit.usedLimit}
                    </Grid>
                  )}
                {Boolean(businessCustomerContract?.credit.availableLimit) && (
                  <Grid item xs={1}>
                    <FormInputLabel
                      label={<TransField i18nKey="availableLimit" />}
                    />
                    {businessCustomerContract?.credit.availableLimit}
                  </Grid>
                )}
                <Grid item xs={1}>
                  <TextField
                    required
                    name="credit.paymentTermInDays"
                    label={<TransField i18nKey="paymentTerm" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    required
                    name="credit.currencyId"
                    label={<TransField i18nKey="currency" />}
                    options={currencyOptions}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ alignItems: 'center' }}
                spacing={2}
                columns={1}
              >
                <Grid item xs={2} sx={{ display: 'flex', mt: 3 }}>
                  <Stack
                    direction="row"
                    sx={{ ml: 'auto', alignItems: 'center' }}
                    spacing={1}
                  >
                    <Button onClick={handleReset} variant="text">
                      <TransButton i18nKey="resetChanges" />
                    </Button>
                    <Button icon="check" type="submit" sx={{ ml: 2 }}>
                      <TransButton i18nKey="save" />
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </FormProvider>
        <FindBusinessCustomersModal
          onSubmit={(businessCustomer) => {
            form.change('businessCustomer', businessCustomer.customer);
            form.change('contractorId', businessCustomer.owner.id);
            form.change('unitId');
          }}
          isOpen={isBusinessCustomersModalOpen}
          onClose={() => setIsBusinessCustomersModalOpen(false)}
        />
      </>
    );
  };
