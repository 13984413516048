import type { FC } from 'react';
import { useCallback } from 'react';
import {
  Button,
  FormControl,
  FormProvider,
  PeriodField,
  RadioGroupField,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useDispatch } from 'store/utils';
import { EmployeeAccountsFilter } from 'dto/businessCustomerContracts';
import {
  getEmployeeAccounts,
  setEmployeeAccountsFilter,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';

interface EmployeeAccountsSearchFormProps {}

export const EmployeeAccountsSearchForm: FC<EmployeeAccountsSearchFormProps> =
  () => {
    const cardStatuses = useClassificationOptions(
      ClassificationGroup.CLIENT_CONTRACT_CARD_STATUS
    );
    const dispatch = useDispatch();

    const onSubmit = useCallback(
      (formValues: EmployeeAccountsFilter) => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        dispatch(getEmployeeAccounts(formValues));
      },
      [dispatch]
    );

    const { form, handleSubmit } = useForm({
      onSubmit,
    });

    const handleReset = useCallback(() => {
      form.reset();
      dispatch(setEmployeeAccountsFilter({}));
    }, [form, dispatch]);

    return (
      <SearchForm title={<TransSubtitle i18nKey="search" />}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              sx={{ alignItems: 'center' }}
              spacing={2}
              columns={4}
            >
              <Grid item xs={1}>
                <TextField
                  name="name"
                  label={<TransField i18nKey="accountName" />}
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="statusId"
                  label={<TransField i18nKey="status" />}
                  options={cardStatuses}
                />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  options="BOOL"
                  name="isAccountManager"
                  inline
                  label={<TransField i18nKey="isCompanyAccountManager" />}
                />
              </Grid>
              <PeriodField
                from={{
                  name: 'validityStartFrom',
                  label: <TransField i18nKey="validFrom" />,
                  isClearable: true,
                }}
                to={{
                  name: 'validityStartTo',
                  isClearable: true,
                }}
              />
              <PeriodField
                from={{
                  name: 'validityEndFrom',
                  label: <TransField i18nKey="validUntil" />,
                  isClearable: true,
                }}
                to={{
                  name: 'validityEndTo',
                  isClearable: true,
                }}
              />
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFields" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button variant="contained" type="submit" icon="search">
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
