import { FC, useCallback, useEffect, useMemo } from 'react';
import { CardContent, Grid, Stack } from '@mui/material';
import {
  Button,
  FormProvider,
  formSubmit,
  SearchForm,
  SelectField,
  useForm,
} from '@fleet/shared';
import { RadioGroupField, TextField } from '@fleet/shared/form';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Icon } from '@fleet/shared/mui';
import { LoyaltyParameters } from 'dto/loyaltyProgram';
import { useDispatch, useSelector } from 'store/utils';
import {
  getLoyaltyParameters,
  updateLoyaltyParameters,
} from 'features/loyaltyProgram/loyaltyProgramActions';
import { loyaltyParametersSelector } from 'features/loyaltyProgram/loyaltyProgramSelectors';
import { assigningRuleOptions, conversionRuleOptions } from 'consts';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';

export const LoyaltyParametersForm: FC = () => {
  const dispatch = useDispatch();
  const loyaltyParameters = useSelector(loyaltyParametersSelector);
  const vouchers = useClassificationOptions(ClassificationGroup.VOUCHERS);

  useEffect(() => {
    dispatch(getLoyaltyParameters());
  }, [dispatch]);

  const initialValues = useMemo(() => loyaltyParameters, [loyaltyParameters]);

  const onSubmit = useCallback(
    async (payload: LoyaltyParameters) =>
      formSubmit(async () => {
        const { pointsAssigningRule, pointsConversionRule, ...rest } = payload;

        await dispatch(
          updateLoyaltyParameters({
            ...rest,
            pointsAssigningRuleId: pointsAssigningRule.id,
            pointsConversionRuleId: pointsConversionRule.id,
          })
        );
        await dispatch(getLoyaltyParameters());
      }),
    [dispatch]
  );
  const { form, handleSubmit, submitting } = useForm<LoyaltyParameters>({
    initialValues,
    onSubmit,
    subscription: { submitting: true, pristine: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="details" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <CardContent sx={{ p: 0 }}>
            <div>
              <Grid container sx={{ pb: 2 }} spacing={3} columns={5}>
                <Grid item xs={1}>
                  <TextField
                    name="loyaltyProgramName"
                    label={<TransField i18nKey="name" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <RadioGroupField
                    name="pointsConversionRule.id"
                    label={<TransField i18nKey="pointsConversionRule" />}
                    options={conversionRuleOptions}
                    inline
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <RadioGroupField
                    name="arePointsUsableOnlyWhenTicketHasCheckedIn"
                    label={
                      <TransField i18nKey="arePointsUsableOnlyWhenTicketHasCheckedIn" />
                    }
                    options="BOOL_ONLY"
                    inline
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="pointsAssigningRule.id"
                    label={<TransField i18nKey="pointsAssigningRule" />}
                    options={assigningRuleOptions}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="loyaltyVoucherServiceId"
                    label={<TransField i18nKey="loyaltyVoucherServiceId" />}
                    options={vouchers}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="pointsValidityInDays"
                    label={<TransField i18nKey="pointsValidityInDays" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="pointsAssignedOnJoiningLoyaltyProgram"
                    label={<TransField i18nKey="pointsAssignedOnJoining" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="pointsAssignedOnBirthday"
                    label={<TransField i18nKey="pointsAssignedOnBirthday" />}
                  />
                </Grid>

                <Grid item xs={1}>
                  <RadioGroupField
                    name="isCorporateCustomerProfileAllowedToJoinLoyaltyProgram"
                    label={
                      <TransField i18nKey="isCorporateCustomerProfileAllowedToJoinLoyaltyProgram" />
                    }
                    options="BOOL_ONLY"
                    inline
                  />
                </Grid>
              </Grid>

              <Stack
                direction="row"
                sx={{ alignItems: 'center', justifyContent: 'end' }}
                spacing={1}
              >
                <Button onClick={handleReset} variant="text">
                  <TransButton i18nKey="resetChanges" />
                </Button>
                <Button
                  disabled={submitting}
                  startIcon={<Icon name="check" />}
                  type="submit"
                >
                  <TransButton i18nKey="save" />
                </Button>
              </Stack>
            </div>
          </CardContent>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
