import { useDispatch, useSelector } from 'store/utils';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import {
  createCustomerProfileCard,
  deleteCustomerProfileCard,
  getCustomerProfileCards,
  updateCustomerProfileCard,
} from 'features/customerProfile/customerProfileActions';
import {
  customerProfileCardFilterSelector,
  customerProfileCardsSelector,
} from 'features/customerProfile/customerProfileSelectors';
import { customerProfileEditLoadingSelector } from 'features/loading/loadingSelectors';
import {
  Checkbox,
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { CustomerProfileCard } from 'dto/customerProfile';
import { usePagination, useRowSelect } from 'react-table';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';

export const Cards = () => {
  const dispatch = useDispatch();
  const cards = useSelector(customerProfileCardsSelector);
  const loading = useSelector(customerProfileEditLoadingSelector);
  const filter = useSelector(customerProfileCardFilterSelector);

  useEffect(() => {
    dispatch(getCustomerProfileCards());
  }, [dispatch]);

  const data = useMemo(() => cards?.items ?? [], [cards]);
  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const getPage = useCallback(
    (pageSize: number) => {
      if (cards) {
        const { limit = pageSize, offset } = cards;
        return offset / limit;
      }
      return 0;
    },
    [cards]
  );

  const columns: TableColumns<CustomerProfileCard> = useMemo(
    () => [
      {
        accessor: 'number',
        Header: <TransTableHead i18nKey="number" />,
        type: 'text',
        editableProps: {
          required: true,
          type: 'number',
        },
      },
      {
        accessor: 'uid',
        Header: 'Uid',
        type: 'text',
        editableProps: {
          required: true,
        },
      },
      {
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="active" />,
        type: 'checkbox',
        editableProps: {
          required: false,
          defaultValue: false,
        },
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getCustomerProfileCards({ ...filter, ...paginationParams })
      ),
    [dispatch, filter]
  );

  const table = useFormTable(
    {
      data,
      columns,
      form,
      pageCount: -1,
      manualPagination: true,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      onPageChange: handlePageChange,
      total: cards?.totalCount,
      onRowUpdate: async (payload) => {
        const { id } = payload;

        await dispatch(
          (id ? updateCustomerProfileCard : createCustomerProfileCard)(payload)
        );

        await dispatch(getCustomerProfileCards());
      },
    },
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    usePagination,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteCustomerProfileCard(
        table.filteredRows.map((row) => row.original.id)
      )
    );
    await dispatch(getCustomerProfileCards());
  }, [dispatch, table.filteredRows]);

  const handleIsActiveFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        getCustomerProfileCards(
          e.target.checked ? { isActive: e.target.checked } : {}
        )
      );
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Box sx={{ mb: 6 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <div>
              <Checkbox
                onChange={handleIsActiveFilterToggle}
                label={
                  <Typography
                    variant="body2"
                    color="text.primary"
                    component="span"
                  >
                    <TransField i18nKey="showActiveCards" />
                  </Typography>
                }
                size="small"
              />
            </div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 'auto' }}
            >
              <Button
                disabled={!table.selectedFlatRows.length}
                variant="text"
                onClick={removeSelectedRows}
                startIcon={<Icon name="delete" />}
                color="error"
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
              <Button
                variant="text"
                onClick={addRow}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
