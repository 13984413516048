import type { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { BusinessCustomer } from 'routes/customers/businessCustomer/BusinessCustomer';
import { BusinessCustomerDetails } from 'routes/customers/businessCustomer/BusinessCustomerDetails';
import { BusinessCustomerContracts } from 'routes/customers/businessCustomerContracts/BusinessCustomerContracts';
import { BusinessCustomerContractsDetails } from 'routes/customers/businessCustomerContracts/BusinessCustomerContractsDetails';

export interface CustomerProfileTypesProps
  extends RouteComponentProps<{ typeId: string }> {}

export const Customers: FC<CustomerProfileTypesProps> = ({
  match: { path },
}) => (
  <Switch>
    <Route
      path={`${path}/business_customers`}
      component={BusinessCustomer}
      exact
    />
    <Route
      path={`${path}/business_customers/(create|edit)/:id?/:tab?/:action(create|edit)?/:entityId?`}
      component={BusinessCustomerDetails}
      exact
    />
    <Route
      path={`${path}/business_customer_contracts`}
      component={BusinessCustomerContracts}
      exact
    />
    <Route
      path={`${path}/business_customer_contracts/(create|edit)/:businessCustomerId?/:contractId?/:tab?/:action(create|edit)?/:entityId?`}
      component={BusinessCustomerContractsDetails}
      exact
    />
  </Switch>
);
