import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DateField,
  Drawer,
  FormProvider,
  Icon,
  Loadable,
  makeClassificationOptions,
  SelectField,
  Tooltip,
  useForm,
} from '@fleet/shared';
import {
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { PeriodField, TextField } from '@fleet/shared/form';
import { CardHeader } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import {
  businessCustomerContactPersonSelector,
  currentBusinessCustomerSelector,
} from 'features/businessCustomer/businessCustomerSelectors';
import { businessCustomerContactPersonLoadingSelector } from 'features/loading/loadingSelectors';
import {
  getBusinessCustomerContactPersons,
  getBusinessCustomerContactPersonsById,
  setBusinessCustomerContactPerson,
  updateOrCreateBusinessCustomerContactPerson,
} from 'features/businessCustomer/businessCustomerActions';
import { BusinessCustomerContactPersonRequest } from 'dto/businessCustomer';
import { TransAlert } from 'i18n/trans/alert';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { fetchUnits } from 'features/businessCustomer/businessCustomerService';
import { Option } from '@fleet/shared/dto/option';

const useStyles = makeStyles(
  () => ({
    action: { alignSelf: 'flex-start' },
    field: { marginBottom: 16 },
  }),
  {
    name: 'ContactPersonDrawerForm',
  }
);

export const ContactPersonsDrawerForm: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { action, entityId } =
    useParams<{ action: 'create' | 'edit'; entityId: string }>();
  const classes = useStyles();
  const loading = useSelector(businessCustomerContactPersonLoadingSelector);
  const contact = useSelector(businessCustomerContactPersonSelector);
  const [unitOptions, setUnitOptions] = useState<Array<Option<number>>>([]);
  const alert = useAlert();
  const isEditing = action === 'edit';
  const businessCustomer = useSelector(currentBusinessCustomerSelector);

  useEffect(() => {
    dispatch(setBusinessCustomerContactPerson());

    if (isEditing && entityId) {
      dispatch(getBusinessCustomerContactPersonsById(entityId));
    }
  }, [dispatch, entityId, isEditing]);

  const closeDrawer = useCallback(() => {
    history.replace(
      `/customers/business_customers/edit/${businessCustomer?.id}`
    );
    dispatch(setBusinessCustomerContactPerson());
  }, [businessCustomer?.id, dispatch, history]);

  const onSubmit = useCallback(
    async (values: BusinessCustomerContactPersonRequest) => {
      await dispatch(updateOrCreateBusinessCustomerContactPerson(values))
        .unwrap()
        .then(() =>
          alert.success(
            <TransAlert
              i18nKey={
                contact?.id
                  ? 'businessCustomerContactProfileUpdated'
                  : 'businessCustomerContactProfileCreated'
              }
            />
          )
        )
        .finally(() => {
          dispatch(getBusinessCustomerContactPersons());
          closeDrawer();
        });
    },
    [alert, closeDrawer, contact?.id, dispatch]
  );

  const initialValues = useMemo(
    () => ({
      ...(contact && {
        ...contact,
        unitId: contact.unit?.id,
      }),
    }),
    [contact]
  );

  const { form, submitting, handleSubmit } = useForm({
    onSubmit,
    initialValues,
    subscription: { submitting: true },
  });

  useEffect(() => {
    const fetchUnitOptions = async () => {
      setUnitOptions(
        makeClassificationOptions(await fetchUnits(businessCustomer!.id))
      );
    };

    if (businessCustomer?.id) {
      fetchUnitOptions();
    }
  }, [businessCustomer]);

  return (
    <Drawer anchor="right" elevation={0} onClose={closeDrawer} open>
      <Loadable loading={loading}>
        <FormProvider {...form}>
          <Stack
            sx={{
              height: '100%',
              width: '424px !important',
              minWidth: '0 !important',
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            <CardHeader
              sx={{ pb: 0 }}
              classes={{ action: classes.action }}
              title={
                <Typography variant="subtitle" component="div">
                  {isEditing ? (
                    <TransSubtitle i18nKey="editBusinessCustomerContact" />
                  ) : (
                    <TransSubtitle i18nKey="newBusinessCustomerContact" />
                  )}
                </Typography>
              }
              action={
                <IconButton aria-label="close" onClick={closeDrawer}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              }
            />
            <CardContent sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
              <Stack sx={{ mt: 2 }} direction="column" spacing={2}>
                <TextField
                  required
                  name="firstName"
                  label={<TransField i18nKey="firstName" />}
                />
                <TextField
                  required
                  name="lastName"
                  label={<TransField i18nKey="lastName" />}
                />
                <TextField
                  name="personalCode"
                  label={<TransField i18nKey="personalCode" />}
                />

                <DateField
                  name="birthDate"
                  label={<TransField i18nKey="birthdate" />}
                />

                <TextField
                  name="position"
                  label={<TransField i18nKey="position" />}
                />

                <SelectField
                  options={unitOptions}
                  name="unitId"
                  label={<TransField i18nKey="unit" />}
                />

                <PeriodField
                  from={{
                    name: 'validity.from',
                    label: <TransField i18nKey="contactPersonSince" />,
                    required: true,
                  }}
                  to={{
                    name: 'validity.to',
                    label: <TransField i18nKey="contactPersonUntil" />,
                    isClearable: true,
                  }}
                  grid={false}
                />

                <TextField
                  name="comment"
                  label={<TransField i18nKey="comment" />}
                />
              </Stack>
            </CardContent>
            <CardActions
              sx={{
                padding: 3,
                justifyContent: 'flex-end',
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Button variant="text" color="primary" onClick={closeDrawer}>
                <TransButton i18nKey="cancel" />
              </Button>
              {entityId ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<Icon name="check" />}
                >
                  <TransButton i18nKey="save" disabled={submitting} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                  startIcon={<Icon name="plus" />}
                >
                  <TransButton i18nKey="add" />
                </Button>
              )}
            </CardActions>
          </Stack>
        </FormProvider>
      </Loadable>
    </Drawer>
  );
};
