import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';

import { useAlert } from 'react-alert';
import {
  Button,
  Drawer,
  FormProvider,
  Icon,
  Loadable,
  PeriodField,
  SelectField,
  Tooltip,
  useForm,
} from '@fleet/shared';
import {
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CardHeader } from '@fleet/shared/mui';
import { RadioGroupField, TextField } from '@fleet/shared/form';
import { LinkCustomerProfileSection } from './LinkCustomerProfileSection';
import { LatestCardSection } from './LatestCardSection';
import { parseISODateString } from '@fleet/shared/utils/date';
import { useDispatch, useSelector } from 'store/utils';
import { employeeAccountEditViewLoadingSelector } from 'features/loading/loadingSelectors';
import {
  currentBusinessCustomerContractSelector,
  employeeAccountSelector,
} from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getEmployeeAccountById,
  getEmployeeAccounts,
  setEmployeeAccount,
  updateOrCreateEmployeeAccount,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import {
  CustomerProfile,
  EmployeeAccount,
} from 'dto/businessCustomerContracts';
import { TransAlert } from 'i18n/trans/alert';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface EmployeeAccountsDrawerFormProps {}

const useStyles = makeStyles(
  () => ({
    action: { alignSelf: 'flex-start' },
    field: { marginBottom: 16 },
  }),
  {
    name: 'EmployeeAccountsDrawerForm',
  }
);

export const EmployeeAccountsDrawerForm: FC<EmployeeAccountsDrawerFormProps> =
  () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { action, entityId } = useParams<{
      action: 'create' | 'edit';
      entityId: string;
    }>();
    const classes = useStyles();
    const loading = useSelector(employeeAccountEditViewLoadingSelector);
    const contract = useSelector(currentBusinessCustomerContractSelector);
    const employeeAccount = useSelector(employeeAccountSelector);
    const alert = useAlert();
    const isEditing = action === 'edit';
    const cardStatuses = useClassificationOptions(
      ClassificationGroup.CLIENT_CONTRACT_CARD_STATUS
    );
    const isMonthlyLimit =
      contract?.credit.type.id === 'CREDIT_TYPE.MONTHLY_LIMIT';

    useEffect(() => {
      dispatch(setEmployeeAccount());

      if (isEditing && entityId) {
        dispatch(getEmployeeAccountById(entityId));
      }
    }, [dispatch, entityId, isEditing]);

    const closeDrawer = useCallback(() => {
      history.replace(
        `/customers/business_customer_contracts/edit/${contract?.businessCustomer.id}/${contract?.id}`
      );
      dispatch(setEmployeeAccount());
    }, [contract?.businessCustomer.id, contract?.id, dispatch, history]);

    const onSubmit = useCallback(
      async ({ credit, status, ...rest }: EmployeeAccount) => {
        const shouldDisableSubmission =
          !rest.customerProfile?.firstName ||
          !rest.customerProfile?.lastName ||
          !rest.customerProfile?.email;

        if (shouldDisableSubmission) {
          alert.error(<TransAlert i18nKey="customerProfileLinkNeeded" />);
          return;
        }

        await dispatch(
          updateOrCreateEmployeeAccount({
            ...rest,
            statusId: status.id,
            monthlyCreditLimit: credit.limit,
            dailyCreditLimit: credit.dailyLimit,
          })
        )
          .unwrap()
          .then(async () => {
            dispatch(getEmployeeAccounts());
            alert.success(
              <TransAlert
                i18nKey={
                  rest?.id ? 'employeeAccountUpdated' : 'employeeAccountCreated'
                }
              />
            );
            closeDrawer();
          });
      },
      [dispatch, alert, closeDrawer]
    );

    const { form, submitting, handleSubmit } = useForm({
      onSubmit,
      initialValues: employeeAccount,
    });

    const updateFormAfterLinking = useCallback(
      (customerProfile: CustomerProfile) => {
        form.change('customerProfile', customerProfile);
      },
      [form]
    );

    const unlinkCustomerProfile = useCallback(() => {
      form.change('customerProfile', undefined);
    }, [form]);

    const unlinkCustomerProfileCard = useCallback(() => {
      form.change('customerProfileCard', undefined);
    }, [form]);

    const linkCustomerProfileCard = useCallback(() => {
      form.change('customerProfileCard', employeeAccount?.customerProfileCard);
    }, [employeeAccount?.customerProfileCard, form]);

    const getCustomerProfileFormState = useCallback(() => {
      return form.getState().values.customerProfile;
    }, [form]);

    return (
      <Drawer anchor="right" elevation={0} onClose={closeDrawer} open>
        <Loadable loading={loading}>
          <FormProvider {...form}>
            <Stack
              sx={{
                height: '100%',
                width: '1000px !important',
                minWidth: '0 !important',
              }}
              component="form"
              onSubmit={handleSubmit}
            >
              <CardHeader
                sx={{ pb: 0 }}
                classes={{ action: classes.action }}
                title={
                  <Typography variant="subtitle" component="div">
                    {isEditing ? (
                      <TransSubtitle i18nKey="editEmployeeAccount" />
                    ) : (
                      <TransSubtitle i18nKey="newEmployeeAccount" />
                    )}
                  </Typography>
                }
                action={
                  <IconButton aria-label="close" onClick={closeDrawer}>
                    <Tooltip
                      content={<TransButton i18nKey="close" />}
                      delay={500}
                    >
                      <Icon name="close" size={24} />
                    </Tooltip>
                  </IconButton>
                }
              />
              <CardContent sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
                <Grid
                  container
                  sx={{ alignItems: 'center', mt: 3, mb: 3 }}
                  spacing={2}
                  columns={4}
                >
                  <Grid item xs={1}>
                    <SelectField
                      required
                      name="status.id"
                      options={cardStatuses}
                      label={<TransField i18nKey="status" />}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <RadioGroupField
                      name="isAccountManager"
                      label={<TransField i18nKey="isCompanyAccountManager" />}
                      options="BOOL_ONLY"
                      inline
                    />
                  </Grid>
                  <PeriodField
                    from={{
                      name: 'validity.from',
                      label: <TransField i18nKey="validFrom" />,
                      minDate: parseISODateString(contract!.validity.from),
                      required: true,
                    }}
                    to={{
                      name: 'validity.to',
                      label: <TransField i18nKey="validUntil" />,
                      minDate: parseISODateString(contract!.validity.from),
                      required: true,
                    }}
                  />
                  <Grid item xs={1}>
                    <TextField
                      required
                      name="credit.dailyLimit"
                      inputMode="numeric"
                      label={<TransField i18nKey="dailyCreditLimit" />}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      required
                      inputMode="numeric"
                      name="credit.limit"
                      label={
                        isMonthlyLimit ? (
                          <TransField i18nKey="monthlyCreditLimit" />
                        ) : (
                          <TransField i18nKey="permanentCreditLimit" />
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 3 }} />
                <LinkCustomerProfileSection
                  employeeAccount={employeeAccount}
                  updateAfterLinking={updateFormAfterLinking}
                  unlinkProfile={unlinkCustomerProfile}
                  getCustomerProfileFormState={getCustomerProfileFormState}
                />
                <Divider />
                <LatestCardSection
                  unlinkCustomerProfileCard={unlinkCustomerProfileCard}
                  latestCard={employeeAccount?.customerProfileCard}
                  linkCustomerProfileCard={linkCustomerProfileCard}
                />
              </CardContent>
              <CardActions
                sx={{
                  padding: 3,
                  justifyContent: 'flex-end',
                  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Button variant="text" color="primary" onClick={closeDrawer}>
                  <TransButton i18nKey="cancel" />
                </Button>
                {entityId ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting}
                    startIcon={<Icon name="check" />}
                  >
                    <TransButton i18nKey="save" />
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting}
                    startIcon={<Icon name="plus" />}
                  >
                    <TransButton i18nKey="create" />
                  </Button>
                )}
              </CardActions>
            </Stack>
          </FormProvider>
        </Loadable>
      </Drawer>
    );
  };
