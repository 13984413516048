import { FC, useCallback, useEffect } from 'react';
import {
  Button,
  FileField,
  FormProvider,
  Modal,
  SelectField,
  TextField,
  api,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { ImportDetails } from 'dto/loyaltyProgram';
import { Grid } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { listOfReasons, listOfTimeMetrics } from 'consts';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';

interface PointsUploadForm {
  file: Blob;
  validForMultiplier: number;
  validForType: string;
  pointSourceId: string;
}

interface NewImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onImportSubmitSuccess: () => void;
}

export const NewImportModal: FC<NewImportModalProps> = ({
  isOpen,
  onClose,
  onImportSubmitSuccess,
}) => {
  const onSubmit = useCallback(
    (values: PointsUploadForm) =>
      formSubmit(async () => {
        const { file, ...rest } = values;
        const formData = new FormData();
        formData.append('file', file);

        const { fileId } = (
          await api.post<{ fileId: string }>(
            '/loyalty-programs/points/imports/upload-file',
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          )
        ).data;
        await api.post<ImportDetails>('/loyalty-programs/points/imports', {
          fileId,
          ...rest,
        });
        onImportSubmitSuccess();
      }),
    [onImportSubmitSuccess]
  );

  const { form, handleSubmit, values, submitting } = useForm<PointsUploadForm>({
    onSubmit,
    subscription: { values: true, submitting: true },
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
  }, [isOpen, form]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={<TransTitle i18nKey="newImport" />}
      actionButton={
        <form onSubmit={handleSubmit}>
          <Button variant="contained" type="submit" disabled={submitting}>
            <TransButton i18nKey="submit" />
          </Button>
        </form>
      }
      maxWidth="sm"
      fullWidth
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={2} spacing={2}>
            <Grid item xs={1}>
              <FileField name="file" id="loyaltyPointsUpload" />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="pointSourceId"
                label={<TransField i18nKey="reason" />}
                options={listOfReasons}
                disabled={!values.file}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="validForMultiplier"
                label={<TransField i18nKey="validForMultiplier" />}
                type="number"
                disabled={!values.file}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="validForType"
                label={<TransField i18nKey="validForType" />}
                options={listOfTimeMetrics}
                disabled={!values.file}
                required
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Modal>
  );
};
