import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
} from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Row, useFilters, usePagination, useRowSelect } from 'react-table';
import { Box, Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { formatDate } from '@fleet/shared/utils/date';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentBusinessCustomerSelector,
  businessCustomerTabContractsSelector,
} from 'features/businessCustomer/businessCustomerSelectors';
import { businessCustomerTabsLoadingSelector } from 'features/loading/loadingSelectors';
import { getBusinessCustomerContractTab } from 'features/businessCustomer/businessCustomerActions';
import { BusinessCustomerContract } from 'dto/businessCustomerContracts';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';

interface BusinessCustomerContractsProps {}

export const Contract: FC<BusinessCustomerContractsProps> = () => {
  const contracts = useSelector(businessCustomerTabContractsSelector);
  const data = useMemo(() => contracts?.items ?? [], [contracts]);
  const history = useHistory();
  const dispatch = useDispatch();
  const businessCustomer = useSelector(currentBusinessCustomerSelector);
  const loading = useSelector(businessCustomerTabsLoadingSelector);

  useEffect(() => {
    dispatch(getBusinessCustomerContractTab());
  }, [dispatch]);

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const getPage = useCallback(
    (pageSize: number) => {
      if (contracts) {
        const { limit = pageSize, offset } = contracts;
        return offset / limit;
      }
      return 0;
    },
    [contracts]
  );

  const columns: TableColumns<BusinessCustomerContract> = useMemo(
    () => [
      {
        id: 'contractNumber',
        accessor: 'contractNumber',
        Header: <TransTableHead i18nKey="contractNumber" />,
        Cell: ({ row }: { row: Row<BusinessCustomerContract> }) => (
          <NavLink
            to={`/customers/business_customer_contracts/edit/${row.original.businessCustomer?.id}/${row.original?.id}`}
          >
            {row.original.contractNumber}
          </NavLink>
        ),
      },
      {
        accessor: 'referenceNumber',
        Header: <TransTableHead i18nKey="referenceNumber" />,
        editableProps: {
          required: false,
        },
      },
      {
        id: 'validity.from',
        accessor: ({ validity }) => formatDate(validity?.from),
        Header: <TransTableHead i18nKey="startDate" />,
        type: 'date',
        editableProps: {
          required: true,
        },
      },
      {
        id: 'validity.to',
        accessor: ({ validity }) => formatDate(validity?.to),
        Header: <TransTableHead i18nKey="endDate" />,
        type: 'date',
        editableProps: {
          required: true,
        },
      },
      {
        id: 'credit.limit',
        accessor: ({ credit }) => credit?.limit,
        Header: <TransTableHead i18nKey="creditLimit" />,
        editableProps: {
          required: true,
        },
      },
      {
        id: 'credit.usedLimit',
        accessor: ({ credit }) => credit?.usedLimit,
        Header: <TransTableHead i18nKey="usedLimit" />,
        editableProps: {
          required: true,
        },
      },
      {
        id: 'credit.availableLimit',
        accessor: ({ credit }) => credit?.availableLimit,
        Header: <TransTableHead i18nKey="availableLimit" />,
        editableProps: {
          disabled: true,
          required: false,
        },
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getBusinessCustomerContractTab(paginationParams)),
    [dispatch]
  );

  const table = useFormTable(
    {
      data,
      columns,
      form,
      initialState: {
        pageSize: 10,
      },
      pageCount: -1,
      manualPagination: true,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      onPageChange: handlePageChange,
      total: contracts?.totalCount,
    },
    useFilters,
    usePagination,
    useRowSelect
  );

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Box sx={{ mb: 6 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ ml: 'auto', mb: 1 }}
          >
            <Button
              variant="text"
              onClick={() =>
                history.push({
                  pathname: `/customers/business_customer_contracts/create`,
                  state: {
                    businessCustomer: {
                      id: businessCustomer?.id,
                      name: businessCustomer?.name,
                    },
                    contractorId: businessCustomer?.owner.id,
                  },
                })
              }
              startIcon={<Icon name="plus" />}
            >
              <TransButton i18nKey="addNew" />
            </Button>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
