import { FC, useEffect } from 'react';
import { AddButton, Layout } from '@fleet/shared';
import { CardHeader } from '@fleet/shared/mui';
import { Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { BusinessCustomerContractsSearch } from './BusinessCustomerContractsSearch';
import { BusinessCustomerContractsTable } from './BusinessCustomerContractsTable';
import { useTranslation } from 'react-i18next';
import { TransHeader } from 'i18n/trans/header';
import { TransButton } from 'i18n/trans/button';

export const BusinessCustomerContracts: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    document.title = t(
      'title.businessCustomerContracts',
      'Business customer contracts'
    );
  }, [t]);

  return (
    <Layout
      header={
        <CardHeader title={<TransHeader i18nKey="businessCustomerContracts" />}>
          <AddButton
            onClick={() =>
              history.push('/customers/business_customer_contracts/create')
            }
          >
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
    >
      <>
        <BusinessCustomerContractsSearch />
        <Divider />
        <BusinessCustomerContractsTable />
      </>
    </Layout>
  );
};
