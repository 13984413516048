import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { useRowSelect } from 'react-table';
import { Box, Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerTabsLoadingSelector } from 'features/loading/loadingSelectors';
import { businessCustomerBankAccountSelector } from 'features/businessCustomer/businessCustomerSelectors';
import {
  deleteBusinessCustomerBankAccounts,
  getBusinessCustomerBankAccounts,
  updateOrCreateBusinessCustomerBankAccounts,
} from 'features/businessCustomer/businessCustomerActions';
import { BusinessCustomerBankAccount } from 'dto/businessCustomer';
import { TransTableHead } from 'i18n/trans/table';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';

export const BankAccounts: FC = () => {
  const bankAccounts = useSelector(businessCustomerBankAccountSelector);
  const data = useMemo(() => bankAccounts ?? [], [bankAccounts]);
  const loading = useSelector(businessCustomerTabsLoadingSelector);
  const alert = useAlert();
  const dispatch = useDispatch();
  const bankOptions = useClassificationOptions(ClassificationGroup.BANKS);

  useEffect(() => {
    dispatch(getBusinessCustomerBankAccounts());
  }, [dispatch]);

  const columns = useMemo<TableColumns<BusinessCustomerBankAccount>>(
    () => [
      {
        id: 'bank.id',
        accessor: ({ bank }) => bank?.id,
        Header: <TransTableHead i18nKey="bankName" />,
        type: 'select',
        editableProps: {
          options: bankOptions,
          required: true,
        },
      },
      {
        id: 'accountNumber',
        accessor: 'accountNumber',
        Header: <TransTableHead i18nKey="accountNumber" />,
        editableProps: {
          required: true,
        },
      },
      {
        id: 'iban',
        accessor: 'iban',
        Header: <TransTableHead i18nKey="iban" />,
        editableProps: {
          required: false,
        },
      },
      {
        id: 'swift',
        accessor: 'swift',
        Header: <TransTableHead i18nKey="swift" />,
        editableProps: {
          required: false,
        },
      },
    ],
    [bankOptions]
  );

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      form,
      data,
      columns,
      onRowUpdate: async (bankAccount) => {
        await dispatch(updateOrCreateBusinessCustomerBankAccounts(bankAccount))
          .unwrap()
          .then(() => {
            dispatch(getBusinessCustomerBankAccounts());
            alert.success(
              <TransAlert
                i18nKey={
                  bankAccount.id
                    ? 'businessCustomerBankAccountUpdated'
                    : 'businessCustomerBankAccountCreated'
                }
              />
            );
          });
      },
    },
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteBusinessCustomerBankAccounts(
        table.selectedFlatRows.map((row) => row.original.id)
      )
    )
      .unwrap()
      .then(() => {
        dispatch(getBusinessCustomerBankAccounts());
        alert.success(
          <TransAlert i18nKey="businessCustomerBankAccountRemoved" />
        );
      });
  }, [alert, dispatch, table.selectedFlatRows]);

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Box sx={{ mb: 6 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 'auto' }}
            >
              <Button
                variant="text"
                onClick={removeSelectedRows}
                startIcon={<Icon name="delete" />}
                disabled={!table.selectedFlatRows.length}
                color="error"
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
              <Button
                variant="text"
                onClick={addRow}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
