import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  Button,
  Icon,
  Loadable,
  Table,
  TableColumns,
  Tooltip,
} from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { ImportDetails } from 'dto/loyaltyProgram';
import { usePagination, useTable } from 'react-table';
import { TransTableHead } from 'i18n/trans/table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { NewImportModal } from 'routes/loyaltyProgram/tabs/PointsImport/NewImportModal';
import { makeStyles } from '@mui/styles';
import { ImportDetailsModal } from 'routes/loyaltyProgram/tabs/PointsImport/ImportDetailsModal';
import { useDispatch, useSelector } from 'store/utils';
import { getLoyaltyPointsImportList } from 'features/loyaltyProgram/loyaltyProgramActions';
import { pointsImportLoadingSelector } from 'features/loading/loadingSelectors';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransField } from 'i18n/trans/field';
import { loyaltyPointsImportListSelector } from 'features/loyaltyProgram/loyaltyProgramSelectors';

const useStyles = makeStyles(
  () => ({
    importLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
  {
    name: 'PointsImport',
  }
);

export const PointsImport: FC = () => {
  const [selectedImportDetails, setSelectedImportDetails] = useState<
    ImportDetails | undefined
  >();
  const [isNewImportModalOpened, setIsNewImportModalOpened] =
    useState<boolean>(false);
  const [paginationParams, setPaginationParams] = useState<
    PaginationParams | undefined
  >();
  const importList = useSelector(loyaltyPointsImportListSelector);
  const data = useMemo(() => importList?.items ?? [], [importList]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useSelector(pointsImportLoadingSelector);

  useEffect(() => {
    dispatch(getLoyaltyPointsImportList(paginationParams));
  }, [dispatch, paginationParams]);

  const columns = useMemo<TableColumns<ImportDetails>>(
    () => [
      {
        accessor: 'id',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row }) => (
          <Typography
            className={classes.importLink}
            fontSize={12}
            color="primary.main"
            onClick={() => setSelectedImportDetails(row.original)}
          >
            {row.original.fileName}
          </Typography>
        ),
      },
      {
        accessor: 'createdBy',
        Header: <TransTableHead i18nKey="createdBy" />,
      },
      {
        id: 'createdOn',
        accessor: ({ createdOn }) =>
          formatDate(createdOn, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="createdOn" />,
      },
      {
        accessor: 'totalRows',
        Header: <TransTableHead i18nKey="totalRows" />,
      },
      {
        accessor: 'errors',
        Header: <TransTableHead i18nKey="errors" />,
      },
      {
        id: 'isActive',
        accessor: ({ isActive }) => (
          <Tooltip
            content={
              <TransField i18nKey={isActive ? 'inProgress' : 'finished'} />
            }
          >
            <Icon name={isActive ? 'switch' : 'check'} />
          </Tooltip>
        ),
        Header: <TransTableHead i18nKey="status" />,
      },
    ],
    [classes]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      setPaginationParams(paginationParams),
    []
  );

  const table = useTable<ImportDetails>(
    {
      data,
      columns,
      pageCount: -1,
      total: importList?.totalCount,
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    usePagination
  );

  return (
    <Loadable loading={loading}>
      <ImportDetailsModal
        details={selectedImportDetails}
        onClose={() => setSelectedImportDetails(undefined)}
      />
      <NewImportModal
        isOpen={isNewImportModalOpened}
        onClose={() => setIsNewImportModalOpened(false)}
        onImportSubmitSuccess={async () => {
          setIsNewImportModalOpened(false);
          dispatch(getLoyaltyPointsImportList(paginationParams));
        }}
      />
      <Table
        caption={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ pb: 2, gap: '10px' }}
          >
            <Button
              startIcon={<Icon name="refresh" />}
              onClick={() =>
                dispatch(getLoyaltyPointsImportList(paginationParams))
              }
              variant="text"
            >
              <TransButton i18nKey="refresh" />
            </Button>
            <Button
              startIcon={<Icon name="download" />}
              onClick={() => setIsNewImportModalOpened(true)}
            >
              <TransButton i18nKey="newImport" />
            </Button>
          </Stack>
        }
        table={table}
      />
    </Loadable>
  );
};
