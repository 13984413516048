import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { targetGroupsSelector } from 'features/loyaltyProgram/loyaltyProgramSelectors';
import {
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { LoyaltyTier, TargetGroup } from 'dto/loyaltyProgram';
import { TransTableHead } from 'i18n/trans/table';
import { Row, useExpanded, useRowSelect } from 'react-table';
import { Box, Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import {
  deleteTargetGroups,
  getTargetGroupsWithLocalizations,
  updateOrCreateTargetGroup,
} from 'features/loyaltyProgram/loyaltyProgramActions';
import { Localizations } from 'routes/loyaltyProgram/tabs/TargetGroups/Localizations';
import { targetGroupsLoadingSelector } from 'features/loading/loadingSelectors';

interface TargetGroupsProps {}

export const TargetGroups: FC<TargetGroupsProps> = () => {
  const dispatch = useDispatch();
  const data = useSelector(targetGroupsSelector) ?? [];
  const loading = useSelector(targetGroupsLoadingSelector);

  useEffect(() => {
    dispatch(getTargetGroupsWithLocalizations());
  }, [dispatch]);

  const columns: TableColumns<TargetGroup> = useMemo(
    () => [
      {
        id: 'expander',
        width: 10,
        Cell: ({ row }: { row: Row<LoyaltyTier> }) => {
          return row.original.id ? (
            <Icon
              {...row.getToggleRowExpandedProps()}
              name={`chevron-${row.isExpanded ? 'down' : 'right'}`}
            />
          ) : (
            <></>
          );
        },
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        accessor: 'localizations',
        Header: <TransTableHead i18nKey="localizations" />,
        Cell: ({ value }) => <div>{(value ?? []).length}</div>,
      },
    ],
    []
  );
  const { form } = useForm<{ rows: Array<TargetGroup> }>({
    initialValues: {
      rows: data,
    },
  });
  const table = useFormTable(
    {
      data,
      columns,
      form,
      autoResetExpanded: false,
      onRowUpdate: async (payload) => {
        try {
          await dispatch(updateOrCreateTargetGroup(payload)).unwrap();
          await dispatch(getTargetGroupsWithLocalizations()).unwrap();
        } catch {
          console.log('Target Group PUT/POST failed');
        }
      },
    },
    useExpanded,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: TargetGroup[]) => {
      await dispatch(deleteTargetGroups(payload));
      await dispatch(getTargetGroupsWithLocalizations());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Box sx={{ mb: 6 }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Button
              disabled={!table.selectedFlatRows.length}
              variant="text"
              onClick={removeSelectedRows}
              startIcon={<Icon name="delete" />}
              color="error"
            >
              <TransButton i18nKey="deleteSelected" />
            </Button>
            <Button
              variant="text"
              onClick={addRow}
              startIcon={<Icon name="plus" />}
            >
              <TransButton i18nKey="addNew" />
            </Button>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
                backgroundColor: '#F6F6F6',
              },
            }}
            getRowProps={() => ({
              sx: {
                backgroundColor: 'white',
              },
              onClick: () => null,
            })}
            getCellProps={(cell, meta) => {
              const isExpanderCell = cell.key?.toString().includes('expander');
              const isLocalizationsCell = cell.key
                ?.toString()
                .includes('localizations');
              const isRowEdited = meta.cell.row.state.editable;

              if (!isRowEdited) {
                return {};
              }

              return {
                sx: {
                  verticalAlign:
                    isExpanderCell || isLocalizationsCell
                      ? 'middle'
                      : 'inherit',
                },
              };
            }}
            getSubRow={({ original }) => (
              <Localizations targetGroup={original} />
            )}
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
