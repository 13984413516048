import { CardHeader, Layout } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { LoyaltyConfigurations } from 'routes/loyaltyProgram/LoyaltyConfigurations';
import { LoyaltyParametersForm } from 'routes/loyaltyProgram/LoyaltyParametersForm';
import { useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

export const LoyaltyProgram = () => {
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  if (!currentBusinessEntityId) {
    return null;
  }

  return (
    <>
      <div>
        <Layout
          header={
            <CardHeader
              title={
                <TransTitle
                  i18nKey="loyaltyProgram"
                  values={{
                    name: businessEntities?.find(
                      (entity) => entity.id === currentBusinessEntityId
                    )?.name,
                  }}
                />
              }
            />
          }
        >
          <LoyaltyParametersForm />
        </Layout>
      </div>
      <LoyaltyConfigurations />
    </>
  );
};
