import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { loyaltyProgramTiersSelector } from 'features/loyaltyProgram/loyaltyProgramSelectors';
import {
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  Tooltip,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { LoyaltyTier } from 'dto/loyaltyProgram';
import { TransTableHead } from 'i18n/trans/table';
import { Row, useExpanded, useRowSelect, useSortBy } from 'react-table';
import { Box, Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import {
  getLoyaltyProgramTiersWithLocalizations,
  updateLoyaltyProgramTier,
} from 'features/loyaltyProgram/loyaltyProgramActions';
import { TierLocalizations } from 'routes/loyaltyProgram/tabs/Tiers/Localizations';
import { loyaltyProgramTiersLoadingSelector } from 'features/loading/loadingSelectors';
import { TransField } from 'i18n/trans/field';
import { FormTableRowUpdate } from '@fleet/shared/hooks/useFormTable';
import _ from 'lodash';

interface TargetGroupsProps {}

export const Tiers: FC<TargetGroupsProps> = () => {
  const dispatch = useDispatch();
  const tiers = useSelector(loyaltyProgramTiersSelector);
  const loading = useSelector(loyaltyProgramTiersLoadingSelector);
  const data = useMemo(() => tiers ?? [], [tiers]);
  const sorted = useMemo(() => [{ id: 'pointsFrom', desc: false }], []);

  useEffect(() => {
    dispatch(getLoyaltyProgramTiersWithLocalizations());
  }, [dispatch]);

  const columns: TableColumns<LoyaltyTier> = useMemo(
    () => [
      {
        id: 'expander',
        width: 10,
        Cell: ({ row }: { row: Row<LoyaltyTier> }) => {
          return row.original.id ? (
            <Icon
              {...row.getToggleRowExpandedProps()}
              name={`chevron-${row.isExpanded ? 'down' : 'right'}`}
            />
          ) : (
            <></>
          );
        },
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        accessor: 'pointsFrom',
        Header: <TransTableHead i18nKey="pointsFrom" />,
        type: 'text',
        editableProps: {
          type: 'number',
        },
        conditions: {
          disabled: {
            when: 'pointsFrom',
            is: (_, cell) => cell.sortedRows[0].index !== cell.row.index,
          },
        },
        wrapper: (field, cell) => {
          if (!cell.row.original.id && cell.rows.length > 1) {
            return (
              <Tooltip
                placement="top"
                content={<TransField i18nKey="pointsFromDisabledTooltip" />}
              >
                <span>{cell.value}</span>
              </Tooltip>
            );
          }

          return field;
        },
      },
      {
        accessor: 'pointsTo',
        type: 'text',
        editableProps: {
          type: 'number',
        },
        Header: <TransTableHead i18nKey="pointsTo" />,
      },
      {
        accessor: 'rate',
        type: 'text',
        editableProps: {
          type: 'number',
        },
        Header: <TransTableHead i18nKey="rate" />,
      },
      {
        accessor: 'localizations',
        Header: <TransTableHead i18nKey="localizations" />,
        Cell: ({ value }) => <div>{(value ?? []).length}</div>,
      },
    ],
    []
  );
  const { form, values } = useForm<{ rows: Array<LoyaltyTier> }>({
    initialValues: {
      rows: data,
    },
    subscription: { values: true },
  });

  const handleRowUpdate = useCallback<
    FormTableRowUpdate<LoyaltyTier>
  >(async () => {
    const sortedFormValues = _.sortBy(values.rows, (o) => o.pointsFrom);

    await dispatch(updateLoyaltyProgramTier(sortedFormValues));
    await dispatch(getLoyaltyProgramTiersWithLocalizations());
  }, [dispatch, values.rows]);

  const table = useFormTable(
    {
      data,
      columns,
      form,
      initialState: {
        sortBy: sorted,
      },
      autoResetExpanded: false,
      onRowUpdate: handleRowUpdate,
    },
    useSortBy,
    useExpanded,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: LoyaltyTier[]) => {
      const alteredFormData = values.rows.filter(
        (row) => !payload.includes(row)
      );

      await dispatch(updateLoyaltyProgramTier(alteredFormData));
      await dispatch(getLoyaltyProgramTiersWithLocalizations());
    },
    [dispatch, values.rows]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  const handleAddRow = useCallback(async () => {
    const pointsTo =
      Math.max(...table.rows.map((row) => row.original.pointsTo)) + 1;
    await addRow(undefined, { pointsFrom: pointsTo });
  }, [table.rows, addRow]);

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Box sx={{ mb: 6 }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Button
              variant="text"
              disabled={Boolean(!table.selectedFlatRows.length)}
              onClick={removeSelectedRows}
              startIcon={<Icon name="delete" />}
              color="error"
            >
              <TransButton i18nKey="deleteSelected" />
            </Button>
            <Button
              variant="text"
              onClick={handleAddRow}
              startIcon={<Icon name="plus" />}
            >
              <TransButton i18nKey="addNew" />
            </Button>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
                backgroundColor: '#F6F6F6',
              },
            }}
            getRowProps={() => ({
              sx: {
                backgroundColor: 'white',
              },
              onClick: () => null,
            })}
            getCellProps={(cell, meta) => {
              const isExpanderCell = cell.key?.toString().includes('expander');
              const isLocalizationsCell = cell.key
                ?.toString()
                .includes('localizations');
              const isRowEdited = meta.cell.row.state.editable;

              if (!isRowEdited) {
                return {};
              }

              return {
                sx: {
                  verticalAlign:
                    isExpanderCell || isLocalizationsCell
                      ? 'middle'
                      : 'inherit',
                },
              };
            }}
            getSubRow={({ original }) => <TierLocalizations tier={original} />}
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
