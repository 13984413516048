import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import {
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { Icon } from '@fleet/shared/mui';
import { useRowSelect } from 'react-table';
import { Box, Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerTabsLoadingSelector } from 'features/loading/loadingSelectors';
import { businessCustomerCommentsSelector } from 'features/businessCustomer/businessCustomerSelectors';
import {
  deleteBusinessCustomerComments,
  getBusinessCustomerComments,
  updateOrCreateBusinessCustomerComments,
} from 'features/businessCustomer/businessCustomerActions';
import { BusinessCustomerComment } from 'dto/businessCustomer';
import { TransTableHead } from 'i18n/trans/table';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';

export const Comments: FC = () => {
  const comments = useSelector(businessCustomerCommentsSelector);
  const data = useMemo(() => comments ?? [], [comments]);
  const loading = useSelector(businessCustomerTabsLoadingSelector);
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessCustomerComments());
  }, [dispatch]);

  const columns = useMemo<TableColumns<BusinessCustomerComment>>(
    () => [
      {
        id: 'comment',
        accessor: 'comment',
        Header: <TransTableHead i18nKey="comment" />,
        editableProps: {
          required: true,
        },
      },
    ],
    []
  );

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      form,
      data,
      columns,
      onRowUpdate: async (customerComment) => {
        await dispatch(updateOrCreateBusinessCustomerComments(customerComment))
          .unwrap()
          .then(() => {
            dispatch(getBusinessCustomerComments());
            alert.success(
              <TransAlert
                i18nKey={
                  customerComment.id
                    ? 'businessCustomerCommentUpdated'
                    : 'businessCustomerCommentCreated'
                }
              />
            );
          });
      },
    },
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteBusinessCustomerComments(
        table.selectedFlatRows.map((row) => row.original.id)
      )
    )
      .unwrap()
      .then(() => {
        dispatch(getBusinessCustomerComments());
        alert.success(<TransAlert i18nKey="businessCustomerCommentRemoved" />);
      });
  }, [alert, dispatch, table.selectedFlatRows]);

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Box sx={{ mb: 6 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 'auto' }}
            >
              <Button
                variant="text"
                onClick={removeSelectedRows}
                startIcon={<Icon name="delete" />}
                disabled={!table.selectedFlatRows.length}
                color="error"
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
              <Button
                variant="text"
                onClick={addRow}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
