import type { FC } from 'react';
import { useCallback } from 'react';
import { Grid, Stack } from '@mui/material';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  PeriodField,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { currentBusinessCustomerContractSelector } from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { useDispatch, useSelector } from 'store/utils';
import { ClassificationGroup } from 'dto/classification';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';
import {
  getSpecialDiscounts,
  getTravelPassDiscounts,
  resetSpecialDiscountsSearchTable,
  resetTravelPassDiscountsSearchTable,
  setContractDiscountsFilter,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { ContractDiscountSearchFilter } from 'dto/businessCustomerContracts';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';

interface TravelPassDiscountSearchProps {
  discountType: 'special' | 'travel';
}

export const BusinessContractDiscountModalSearch: FC<TravelPassDiscountSearchProps> =
  ({ discountType }) => {
    const dispatch = useDispatch();
    const contract = useSelector(currentBusinessCustomerContractSelector);
    const calculationTypes = useClassificationOptions(
      ClassificationGroup.BONUS_SCHEME_DISCOUNT_TYPE
    );

    useComponentWillUnmount(() => {
      dispatch(setContractDiscountsFilter({}));
      dispatch(resetTravelPassDiscountsSearchTable());
      dispatch(resetSpecialDiscountsSearchTable());
    });

    const onSubmit = useCallback(
      (formFilter: ContractDiscountSearchFilter) =>
        formSubmit(async () => {
          (document.activeElement as HTMLInputElement)?.blur?.();
          if (contract) {
            await dispatch(
              (discountType === 'travel'
                ? getTravelPassDiscounts
                : getSpecialDiscounts)(formFilter)
            );
          }
        }),
      [contract, discountType, dispatch]
    );

    const { form, handleSubmit } = useForm<ContractDiscountSearchFilter>({
      onSubmit,
    });

    const handleReset = useCallback(() => {
      form.reset();
      dispatch(setContractDiscountsFilter({}));
    }, [form, dispatch]);

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ alignItems: 'center' }} spacing={2} columns={4}>
            <Grid item xs={1}>
              <TextField
                name="discountName"
                label={<TransField i18nKey="discountName" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="calculationTypeId"
                options={calculationTypes}
                label={<TransField i18nKey="calculationType" />}
              />
            </Grid>
            <PeriodField
              from={{
                name: 'validityStartFrom',
                label: <TransField i18nKey="validFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'validityStartTo',
                isClearable: true,
              }}
            />
            <PeriodField
              from={{
                name: 'validityEndFrom',
                label: <TransField i18nKey="validUntil" />,
                isClearable: true,
              }}
              to={{
                name: 'validityEndTo',
                isClearable: true,
              }}
            />
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFields" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    );
  };
